import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import { useHistory } from "react-router-dom";
import { getPolygonContract, getProfileNameFromAddress } from "../ContractHelpers.js"
import {  getFollowersOfAddress,
          getFollowingOfAddress,
          getNextFollowersOfAddress,
          getNextFollowingOfAddress
          } from "../FirebaseRetrieval.js"
import { DispatchGroup } from "../Utils/DispatchGroup.js"
import FollowButton from "./FollowButton.js"
import ProfileNFT from "../Profile/Avatar.js"

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

var profilesSet = new Set()

function FollowersDialog(props) {
  const { onClose, open, showFollowers, provider, account, profileAccount, count } = props;

  const [accounts, setAccounts] = useState([]);
  const [networkNames, setNetworkNames] = useState({})

  let history = useHistory();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
//    history.push("/" + value);
    window.location.href = "/" + value;
    onClose();
  };

  useEffect(() => {
    async function fetchNetwork() {
      if (showFollowers) {
        getFollowersOfAddress(profileAccount, (networkRetrieved) => {
          networkRetrieved.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          setAccounts(networkRetrieved)
          for (var i=0; i<networkRetrieved.length; i++) {
            const address = networkRetrieved[i].address
            getProfileNameFromAddress(address, (connection) => {
              setNetworkNames(prevState => ({
                  ...prevState,
                  [address]: connection
              }));
            });
          }
        })
      }
      else {
        getFollowingOfAddress(profileAccount, (networkRetrieved) => {
          networkRetrieved.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          setAccounts(networkRetrieved)

          for (var i=0; i<networkRetrieved.length; i++) {
            const address = networkRetrieved[i].address
            getProfileNameFromAddress(address, (connection) => {
              setNetworkNames(prevState => ({
                  ...prevState,
                  [address]: connection
              }));
            });
          }
        })
      }
    }
    if (open) {
      fetchNetwork()
    }
  }, [open]);

  async function getNextNetwork() {
    const earliestPostTimestamp = accounts[accounts.length-1].timestamp
    if (showFollowers) {
      getNextFollowersOfAddress(profileAccount, earliestPostTimestamp, (networkRetrieved) => {
        networkRetrieved.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        setAccounts(accounts => [...accounts].concat(networkRetrieved));
        for (var i=0; i<networkRetrieved.length; i++) {
          const address = networkRetrieved[i].address
          getProfileNameFromAddress(address, (connection) => {
            setNetworkNames(prevState => ({
                ...prevState,
                [address]: connection
            }));
          });
        }
      })
    }
    else {
      getNextFollowingOfAddress(profileAccount, earliestPostTimestamp, (networkRetrieved) => {
        networkRetrieved.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });
        setAccounts(accounts => [...accounts].concat(networkRetrieved));
        for (var i=0; i<networkRetrieved.length; i++) {
          const address = networkRetrieved[i].address
          getProfileNameFromAddress(address, (connection) => {
            setNetworkNames(prevState => ({
                ...prevState,
                [address]: connection
            }));
          });
        }
      })
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} >
      <DialogTitle sx={{ maxWidth: "90%" }}>{count} {showFollowers ? count==1?"Follower":"Followers" : "Following"}</DialogTitle>
      <IconButton sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],}} onClick={handleClose}>
          <CloseIcon />
      </IconButton>
      <div id="list" style={{marginTop: "5px", width: "350px", maxWidth: "100%", height: "90vh", overflowY: "auto"}}>
        <List sx={{ pt: 0}}>
          {accounts.map((item) => (
            <ListItem key={item.address}>
              <ListItemAvatar style={{ cursor: "pointer"}} onClick={() => handleListItemClick(item.address)}>
                <ProfileNFT profileAccount={item.address} widthpx={"40px"}/>
              </ListItemAvatar>
              <ListItemText style={{ cursor: "pointer"}} onClick={() => handleListItemClick(item.address)} primary={networkNames[item.address]} />
              <FollowButton
                style = {{
                  marginLeft: "30px",
                  marginTop: "45px",
                  float: "right",
                  marginTop: "0px",
                  width: "90px",
                  borderRadius: "20px",
                  fontSize: "14px",
                  fontWeight: 600,
                  textTransform: 'none'
                }}
                provider={provider}
                account={account}
                profileAccount={item.address}
               />
            </ListItem>
          ))}
          { accounts.length < count &&
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <Button onClick={getNextNetwork} variant="text" style={{textTransform: 'none'}} >Load More</Button>
            </Box>
          }
           <div key="padding" style={{height: "50vh"}} ></div> {/* padding at bottom */}
        </List>
      </div>
    </Dialog>
  );
}

FollowersDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function FollowLabel({ provider, account, count, showFollowers, profileAccount }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (count > 0) {
      setOpen(true);
    }
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      { showFollowers ? <button onClick={handleClickOpen} className={styles.followLabel}><strong>{count}</strong>
        { count === 1 ? " Follower" : " Followers" }
      </button>
        : <button onClick={handleClickOpen} className={styles.followLabel}><strong>{count}</strong> Following</button>
      }
      <FollowersDialog
        open={open}
        onClose={handleClose}
        showFollowers={showFollowers}
        provider={provider}
        account={account}
        profileAccount={profileAccount}
        count={count}
      />
    </>
  );
}

export default FollowLabel;
