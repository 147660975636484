import { create, ipfsClient, urlSource, globSource } from 'ipfs-http-client'
const CID = require('cids')
const axios = require('axios');

export const pinImageToIPFS = async (file) => {
//  const client = create('https://ipfs.infura.io:5001/api/v0')

  const projectId = '20lIK0oJl2PDNfqf9sTTi9cDtAr'
  const projectSecret = 'a6a6e3919b1dd082279717f0ab58f02c'
  const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

  const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: auth
    }
  })
  try {
    const { cid } = await client.add(urlSource(file))
    return cid.toString()
  } catch (error) {
    console.log('Error uploading file: ', error)
  }
};

export const pinFileToIPFS = async (file) => {
//  const client = create('https://ipfs.infura.io:5001/api/v0')

  const projectId = '20lIK0oJl2PDNfqf9sTTi9cDtAr'
  const projectSecret = 'a6a6e3919b1dd082279717f0ab58f02c'
  const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

  const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
      authorization: auth
    }
  })
  try {
    const added = await client.add(file)
    return added.path
  } catch (error) {
    console.log('Error uploading file: ', error)
  }
};


export default { pinFileToIPFS, pinImageToIPFS }