import React, { useEffect, useState, useRef } from "react";
import { useHistory, Router, Link } from "react-router-dom";
import {createBrowserHistory} from 'history';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IosShareIcon from '@mui/icons-material/IosShare';
import CachedIcon from '@mui/icons-material/Cached';
import CircularProgress from '@mui/material/CircularProgress';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
import PublishIcon from '@mui/icons-material/Publish';
import openseaLogo from '../opensea.png';
import openseaLogoFake from '../opensea_fake.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'

import {  ReplyDialog,
          SuccessDialog,
          SimpleSuccessDialog,
          StartedTransactionDialog,
          LoadingTransactionDialog,
          ClipboardDialog,
          BigImageDialog,
          UpvotersRepostersDialog,
          MintFakePostDialog
       } from "../Dialogs.js"

import {  post_contract_address,
          switchToPolygonTestnet,
          getProfileNameFromAddress,
          upvotePost,
          removeUpvotePost,
          repostPost,
          removeRepostPost,
          deletePost,
          getImageFromNFTContract,
          getChainIdFromNFTContract,
          getImageFromNFT,
          blockExplorerTx
       } from "../ContractHelpers.js"

import {
          formatUpvoteTransaction,
          formatRemoveUpvoteTransaction,
          formatRepostTransaction,
          formatRemoveRepostTransaction,
          formatDeletePostTransaction,
       } from "../ManualTransactions.js"

import {  hasLikedPost,
          hasRepostedPost,
          hasLikedPostPending,
          waitForUpvoteTransaction,
          waitForRemoveUpvoteTransaction,
          waitForRepostTransaction,
          waitForRemoveRepostTransaction,
          getNumLikes,
          getNumReposts,
          getNumReplies,
          isPostFake,
          waitForDeletePostTransaction,
        } from "../FirebaseRetrieval.js"

import { roundCorners, toDataURL } from "../Utils/CanvasUtils.js"
import ProfileNFT from "../Profile/Avatar.js"
import { getFromCache, shouldUseCache, cache } from "../Cache.js"

const sigUtil = require('eth-sig-util');
const ethUtil = require('ethereumjs-util');
const ethers = require('ethers');
const crypto = require('crypto')
const Web3 = require('web3');
const { soliditySha3 } = require("web3-utils");

const Post = (props) => {
  const account = props.account
  const provider = props.provider

  const web3 = new Web3(provider);

  let history = useHistory();
  const [openReplyDialog, setOpenReplyDialog] = useState(false)
  const [openBigImageDialog, setOpenBigImageDialog] = useState(false)
  const [dialogTransactionLink, setDialogTransactionLink] = useState();
  const [dialogTransactionTitle, setDialogTransactionTitle] = useState();
  const [openStartedTransactionDialog, setOpenStartedTransactionDialog] = useState(false);
  const [openTransactionConfirmed, setOpenTransactionConfirmed] = useState(false);
  const [openLoadingTransaction, setOpenLoadingTransaction] = useState(false);
  const [openSimpleConfirmation, setOpenSimpleConfirmation] = useState(false)

  const [openMintFake, setOpenMintFake] = useState(false)
  const [postMinted, setPostMinted] = useState()

  const [newLike, setNewLike] = useState()
  const [fakeNewLike, setFakeNewLike] = useState()
  const [newRepost, setNewRepost] = useState()
  const [fakeNewRepost, setFakeNewRepost] = useState()
  const [likedPost, setLikedPost] = useState()
  const [repostedPost, setRepostedPost] = useState()
  const [fakeReplyCounter, setFakeReplyCounter] = useState()
  const [reposterName, setReposterName] = useState()

  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const openOptions = Boolean(anchorElOptions);

  const [likesCount, setLikesCount] = useState()
  const [repostsCount, setRepostsCount] = useState()
  const [repliesCount, setRepliesCount] = useState()
  const [isFakePost, setIsFakePost] = useState()

  const [postImage, setPostImage] = useState()
  const [postText, setPostText] = useState()
  const [postTextArr, setPostTextArr] = useState([])
  const [imageLoading, setImageLoading] = useState(false)
  const [imageNFTChainId, setImageNFTChainId] = useState()

  const [isNSFW, setIsNSFW] = useState(false)

  const [openUpvotersReposters, setOpenUpvotersReposter] = useState(false)
  const [shouldOpenUpvoters, setShouldOpenUpvoters] = useState(false)

  const [twitterShareLoading, setTwitterShareLoading] = useState(false)

  const [clipboardTitle, setClipboardTitle] = useState("Link copied to clipboard")
  const [openClipboardDialog, setOpenClipboardDialog] = useState(false)

  const myRef = useRef(null)

  const handleCloseLoadingTransaction = (value) => {
    setOpenLoadingTransaction(false);
  };

  async function upvoteNFTPost(tokenId) {
    const random = crypto.randomBytes(256)
    const seed = web3.utils.soliditySha3(random);
    const encodedMessage = await upvotePost(provider, account, tokenId, seed)
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/upvoteTransactionRelay?params=" + encodedMessage
    const response = await fetch(url);
    const data = await response.json();

    if (data.status == "success") {
      const transaction = data.hash;
      console.log(transaction)

      setFakeNewLike(transaction)
      setDialogTransactionTitle("Your upvote is being confirmed!")
      setDialogTransactionLink(blockExplorerTx + transaction)
      setOpenStartedTransactionDialog(true)

      waitForUpvoteTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          // show fail popup
          var runManual = window.confirm("Transaction Failed. Send transaction manually?");
          if (runManual) {
            submitManualUpvoteTransaction(encodedMessage)
          }
          handleCloseStartedTransactionDialog()
        }
        else if (receipt.status == "success") {
          console.log("success")
          setNewLike(receipt.hash)
          handleCloseStartedTransactionDialog()

          setDialogTransactionTitle("Your upvote has been confirmed!")
          setDialogTransactionLink(blockExplorerTx + transaction)
          setOpenTransactionConfirmed(true)
        }
      });
    }
    else if (data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualUpvoteTransaction(encodedMessage)
      }
    }
    else {
      var runManual = window.confirm("Transaction Failed. Send transaction manually?");
      if (runManual) {
        submitManualUpvoteTransaction(encodedMessage)
      }
    }
  }

  const submitManualUpvoteTransaction = (encodedMessage) => {
    formatUpvoteTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setFakeNewLike(transactionHash)
        setDialogTransactionTitle("Your upvote is being confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenStartedTransactionDialog(true)
      }).on('receipt', function(receipt){
        handleCloseStartedTransactionDialog()

        setDialogTransactionTitle("Your upvote has been confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenTransactionConfirmed(true)
      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransactionDialog()
      });
    })
  };

  async function removeUpvoteFromPost(tokenId) {
    const random = crypto.randomBytes(256)
    const seed = web3.utils.soliditySha3(random);
    const encodedMessage = await removeUpvotePost(provider, account, tokenId, seed)
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/removeUpvoteTransactionRelay?params=" + encodedMessage
    const response = await fetch(url);
    const data = await response.json();

    if (data.status == "success") {
      const transaction = data.hash;
      console.log(transaction)

      setFakeNewLike(transaction)
      setDialogTransactionTitle("Your upvote removal is being confirmed!")
      setDialogTransactionLink(blockExplorerTx + transaction)
      setOpenStartedTransactionDialog(true)

      waitForRemoveUpvoteTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          var runManual = window.confirm("Transaction Failed. Send transaction manually?");
          if (runManual) {
            submitManualRemoveUpvoteTransaction(encodedMessage)
          }
          handleCloseStartedTransactionDialog()
        }
        else if (receipt.status == "success") {
          console.log("success")
          setNewLike(receipt.hash)
          handleCloseStartedTransactionDialog()

          setDialogTransactionTitle("Your upvote removal has been confirmed!")
          setDialogTransactionLink(blockExplorerTx + transaction)
          setOpenTransactionConfirmed(true)
        }
      });
    }
    else if (data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualRemoveUpvoteTransaction(encodedMessage)
      }
    }
    else {
      var runManual = window.confirm("Transaction Failed. Send transaction manually?");
      if (runManual) {
        submitManualRemoveUpvoteTransaction(encodedMessage)
      }
    }
  }

  const submitManualRemoveUpvoteTransaction = (encodedMessage) => {
    formatRemoveUpvoteTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setFakeNewLike(transactionHash)
        setDialogTransactionTitle("Your upvote removal is being confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenStartedTransactionDialog(true)
      }).on('receipt', function(receipt){
        handleCloseStartedTransactionDialog()

        setDialogTransactionTitle("Your upvote removal has been confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenTransactionConfirmed(true)
      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransactionDialog()
      });
    })
  };

  async function repost(tokenId) {
    const random = crypto.randomBytes(256)
    const seed = web3.utils.soliditySha3(random);
    const encodedMessage = await repostPost(provider, account, tokenId, seed)
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/repostTransactionRelay?params=" + encodedMessage
    const response = await fetch(url);
    const data = await response.json();

    if (data.status == "success") {
      const transaction = data.hash;
      console.log(transaction)

      setFakeNewRepost(transaction)
      setDialogTransactionTitle("Your repost is being confirmed!")
      setDialogTransactionLink(blockExplorerTx + transaction)
      setOpenStartedTransactionDialog(true)

      waitForRepostTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          var runManual = window.confirm("Transaction Failed. Send transaction manually?");
          if (runManual) {
            submitManualRepostTransaction(encodedMessage)
          }
          handleCloseStartedTransactionDialog()
        }
        else if (receipt.status == "success") {
          console.log("success")
          setNewRepost(receipt.hash)

          handleCloseStartedTransactionDialog()
          setDialogTransactionTitle("Your repost has been confirmed!")
          setDialogTransactionLink(blockExplorerTx + transaction)
          setOpenTransactionConfirmed(true)
        }
      });
    }
    else if (data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualRepostTransaction(encodedMessage)
      }
    }
    else {
      var runManual = window.confirm("Transaction Failed. Send transaction manually?");
      if (runManual) {
        submitManualRepostTransaction(encodedMessage)
      }
    }
  }

  const submitManualRepostTransaction = (encodedMessage) => {
    formatRepostTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setFakeNewRepost(transactionHash)
        setDialogTransactionTitle("Your repost is being confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenStartedTransactionDialog(true)
      }).on('receipt', function(receipt){
        handleCloseStartedTransactionDialog()

        setDialogTransactionTitle("Your repost has been confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenTransactionConfirmed(true)
      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransactionDialog()
      });
    })
  };

  async function removeRepost(tokenId) {
    const random = crypto.randomBytes(256)
    const seed = web3.utils.soliditySha3(random);
    const encodedMessage = await removeRepostPost(provider, account, tokenId, seed)
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/removeRepostTransactionRelay?params=" + encodedMessage
    const response = await fetch(url);
    const data = await response.json();

    if (data.status == "success") {
      const transaction = data.hash;
      console.log(transaction)

      setFakeNewRepost(transaction)
      setDialogTransactionTitle("Your repost removal is being confirmed!")
      setDialogTransactionLink(blockExplorerTx + transaction)
      setOpenStartedTransactionDialog(true)

      waitForRemoveRepostTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          var runManual = window.confirm("Transaction Failed. Send transaction manually?");
          if (runManual) {
            submitManualRemoveRepostTransaction(encodedMessage)
          }
          handleCloseStartedTransactionDialog()
        }
        else if (receipt.status == "success") {
          console.log("success")
          setNewRepost(receipt.hash)
          handleCloseStartedTransactionDialog()

          setDialogTransactionTitle("Your repost removal has been confirmed!")
          setDialogTransactionLink(blockExplorerTx + transaction)
          setOpenTransactionConfirmed(true)
        }
      });
    }
    else if (data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualRemoveRepostTransaction(encodedMessage)
      }
    }
    else {
      var runManual = window.confirm("Transaction Failed. Send transaction manually?");
      if (runManual) {
        submitManualRemoveRepostTransaction(encodedMessage)
      }
    }
  }

  const submitManualRemoveRepostTransaction = (encodedMessage) => {
    formatRemoveRepostTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setFakeNewRepost(transactionHash)
        setDialogTransactionTitle("Your repost removal is being confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenStartedTransactionDialog(true)
      }).on('receipt', function(receipt){
        handleCloseStartedTransactionDialog()

        setDialogTransactionTitle("Your repost removal has been confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenTransactionConfirmed(true)
      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransactionDialog()
      });
    })
  };

  async function reportPost() {
    var messageForServer = {
      tokenId: props.tokenId
    };
    const messageJSON = JSON.stringify(messageForServer);
    var messageBuff = new Buffer(messageJSON);
    var encodedMessage = messageBuff.toString('base64');
    const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/reportPost?params=" + encodedMessage
    fetch(url);
    setAnchorElOptions(null);
    setDialogTransactionTitle("Thanks for your feedback!")
    setOpenSimpleConfirmation(true)
  }

  async function deleteNFTPost() {
    setAnchorElOptions(null);

    if (isFakePost) {
      const web3 = new Web3(provider.provider);

      var signature;
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (!object || object.value == "rejected") { // use provider
        signature = await web3.eth.personal.sign(
          'Sign this message to verify that you are the poster. Delete:' + props.tokenId,
          account,
          '' // MetaMask will ignore the password argument here
        );
      }
      else {
        signature = await web3.eth.accounts.sign('Sign this message to verify that you are the poster. Delete:' + props.tokenId, object.value);
        signature = signature.signature
      }

      setDialogTransactionTitle("Your post is being deleted.")
      setOpenSimpleConfirmation(true)

      var messageForServer = {
          tokenId: props.tokenId,
          signature: signature,
      };
      const messageJSON = JSON.stringify(messageForServer);
      var messageBuff = new Buffer(messageJSON);
      var encodedMessage = messageBuff.toString('base64');
      const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/deleteFake?params=" + encodedMessage
      const response = await fetch(url);
      const data = await response.json();

      if (data.status == "success") {
        if (props.setPostDeleted) {
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }
    }
    else {
      const tokenId = props.tokenId
      const encodedMessage = await deletePost(provider, account, tokenId)
      const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/deletePostTransactionRelay?params=" + encodedMessage
      const response = await fetch(url);
      const data = await response.json();

      if (data.status == "success") {
        const transaction = data.hash;
        console.log(transaction)
        setDialogTransactionTitle("Your post removal is confirming")
        setDialogTransactionLink(blockExplorerTx + transaction)
        setOpenLoadingTransaction(true)

        waitForDeletePostTransaction(0, transaction, function (receipt) {
          if (receipt.status == "fail") {
            var runManual = window.confirm("Transaction Failed. Send transaction manually?");
            if (runManual) {
              submitDeletePostTransaction(encodedMessage)
            }
            handleCloseLoadingTransaction()
          }
          else if (receipt.status == "success") {
            if (props.setPostDeleted) {
              props.setPostDeleted(tokenId)
            }
            handleCloseLoadingTransaction()

            setDialogTransactionTitle("Your post removal has confirmed!")
            setDialogTransactionLink(blockExplorerTx + transaction)
            setOpenTransactionConfirmed(true)
          }
        });
      }
      else if (data.status == "transactionLimit") {
        var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
        if (runManual) {
          submitDeletePostTransaction(encodedMessage)
        }
      }
      else {
        var runManual = window.confirm("Transaction Failed. Send transaction manually?");
        if (runManual) {
          submitDeletePostTransaction(encodedMessage)
        }
      }
    }
  }

  const submitDeletePostTransaction = (encodedMessage) => {
    formatDeletePostTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setDialogTransactionTitle("Your post removal is confirming")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenLoadingTransaction(true)
      }).on('receipt', function(receipt){
        handleCloseLoadingTransaction()
        setDialogTransactionTitle("Your post removal has confirmed!")
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setOpenTransactionConfirmed(true)

        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransactionDialog()
      });
    })
  };

  let replyClick = (e) => {
    e.stopPropagation();
    setOpenReplyDialog(true)
  }

  let repostClick = (e, tokenId) => {
    e.stopPropagation();
    if (repostedPost) {
      removeRepost(tokenId);
    }
    else {
      repost(tokenId);
    }
  }

  let likeClick = (e, tokenId) => {
    e.stopPropagation();
    if (likedPost) {
      removeUpvoteFromPost(tokenId);
    }
    else {
      upvoteNFTPost(tokenId);
    }
  }

  let shareClick = (e, tokenId) => {
    e.stopPropagation();
    if (navigator.clipboard) {
      const shortId = parseInt(tokenId).toString(36).substring(8)
      navigator.clipboard.writeText("https://nfposts.com/p/" + shortId)
    }
    setOpenClipboardDialog(true)
  }

  let openNFTImage = () => {
    const nft_info = props.content.split(";PostNFT:")[1]
    const nft_contract_addr = nft_info.split(",")[0]
    const nft_tokenid = nft_info.split(",")[1]

    var opensea_url = "https://opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
    if (imageNFTChainId == 137) {
        opensea_url = "https://opensea.io/assets/matic/" + nft_contract_addr + "/" + nft_tokenid;
    }
    else if (imageNFTChainId == 4) {
      opensea_url = "https://testnets.opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
    }
    window.open(opensea_url)
  }

  let tweet = async(e, tokenId) => {
    e.stopPropagation();
    setTwitterShareLoading(true)
    var content = props.content.replaceAll('<br/>', '\n')
    const shortId = parseInt(tokenId).toString(36).substring(8)
    if (content) {
      if (content.includes(";PostNFT:")) {
        var postContent = content.split(";PostNFT:")[0]
        postContent = postContent.replace(/\n/g, "%0A")

        const nft_info = content.split(";PostNFT:")[1]
        const nft_address = nft_info.split(",")[0]
        const nft_id = nft_info.split(",")[1]
        getImageFromNFTContract(nft_address, nft_id, async(img) => {
          var text = "https://twitter.com/intent/tweet?text=" + postContent
          text += "%0A%0ANFT: nfposts.com/p/" + shortId

          var messageForServer = {
            imageURL: img
          }
          const messageJSON = JSON.stringify(messageForServer);
          var messageBuff = new Buffer(messageJSON);
          var encodedMessage = messageBuff.toString('base64');
          const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
          const response = await fetch(url);
          const data = await response.json();

          if (data.status == "success") {
            const respData = data.data
            const id = respData["id"].toString()
            const twitterPic = respData["entities"]["media"][0]["display_url"]
            text += "%0A%0A&url=" + encodeURI(twitterPic)
            setTwitterShareLoading(false)
            window.open(text, '_blank')
          }
          else {
            // This gets the image from OpenSea or web3 and NOT from BitProfile.
            try {
              getImageFromNFT(nft_address, nft_id, false, (imgUrl) => {
                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
                setTwitterShareLoading(false)
                window.open(text, '_blank')
              })
            }
            catch {
              getImageFromNFT(nft_address, nft_id, true, (imgUrl) => {
                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
                setTwitterShareLoading(false)
                window.open(text, '_blank')
              })
            }
          }
        })
      }
      else if (content.includes(";IpfsImageHash:")) {
        var postContent = content.split(";IpfsImageHash:")[0]
        postContent = postContent.replace(/\n/g, "%0A")

        const img = "https://ipfs.io/ipfs/" + content.split(";IpfsImageHash:")[1]

        var text = "https://twitter.com/intent/tweet?text=" + postContent
        text += "%0A%0ANFT: nfposts.com/p/" + shortId

        var messageForServer = {
          imageURL: img
        }
        const messageJSON = JSON.stringify(messageForServer);
        var messageBuff = new Buffer(messageJSON);
        var encodedMessage = messageBuff.toString('base64');
        const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
        const response = await fetch(url);
        const data = await response.json();

        if (data.status == "success") {
          const respData = data.data
          const id = respData["id"].toString()
          const twitterPic = respData["entities"]["media"][0]["display_url"]
          text += "%0A%0A&url=" + encodeURI(twitterPic)
        }
        else {
          text += "%0A%0A[Message for tweeter... upload this image manually: " + img + "]"
        }
        setTwitterShareLoading(false)
        window.open(text, '_blank')
      }
      else {
        content = content.replace(/\n/g, "%0A")
        console.log(content)
        var text = "https://twitter.com/intent/tweet?text=" + content
        text += "%0A%0ANFT: nfposts.com/p/" + shortId
        setTwitterShareLoading(false)
        window.open(text, '_blank')
      }
    }
  }

  let openseaClick = (e, tokenId) => {
    e.stopPropagation();
    window.open("https://testnets.opensea.io/assets/mumbai/" + post_contract_address + "/" + tokenId, '_blank')
  }

  let fakeOpenseaClick = (e) => {
    e.stopPropagation();
    setOpenMintFake(true)
  }

  const handleCloseMintFake = () => {
    setOpenMintFake(false)
  }

  const openBigImage = (e) => {
    e.stopPropagation();
    setOpenBigImageDialog(true)
  }

  let goToProfile = (e, name) => {
    e.stopPropagation();
    if (props.posCacheName) {
      cache(props.posCacheName, myRef.current.offsetTop)
    }
//    history.push("/" + name)
    window.location.href = "/" + name
  }

  let postClick = (e) => {
    e.stopPropagation();
    if (props.posCacheName) {
      console.log("top: ", myRef.current.offsetTop)
      cache(props.posCacheName, myRef.current.offsetTop)
    }
    history.push("/post/" + props.tokenId)
  }

  const handleCloseTransactionConfirmed = (value) => {
    setOpenTransactionConfirmed(false)
  }

  const handleCloseStartedTransactionDialog = (value) => {
    setOpenStartedTransactionDialog(false)
  }

  const handleCloseClipboardDialog = (value) => {
    setOpenClipboardDialog(false)
  }

  const handleCloseBigImageDialog = (value) => {
    setOpenBigImageDialog(false)
  }

  const handleCloseReplyDialog = (value) => {
    setOpenReplyDialog(false);
    if (props.setAutoOpenReply) {
      props.setAutoOpenReply(false)
    }
  };

  const handleCloseSimpleConfirmation = () => {
    setOpenSimpleConfirmation(false)
  }

  const openReposters = () => {
    setOpenUpvotersReposter(true)
  }

  const openUpvoters = () => {
    setShouldOpenUpvoters(true)
    setOpenUpvotersReposter(true)
  }

  const handleCloseUpvotersReposters = () => {
    setShouldOpenUpvoters(false)
    setOpenUpvotersReposter(false)
  }

  useEffect(() => {
    if (props.autoOpenReply) {
      setOpenReplyDialog(true)
    }
  }, [props.autoOpenReply]);

  useEffect(() => {
    async function checkIfLikedPost() {
      hasLikedPost(account, props.tokenId, (hasLiked) => {
        setLikedPost(hasLiked)
        if (!hasLiked) {
          checkIfLikedPostPending()
        }
      })
    }
    async function checkIfLikedPostPending() {
      hasLikedPostPending(account, props.tokenId, (hasLiked) => {
        setLikedPost(hasLiked)
      })
    }
    async function getLikesCount() {
      getNumLikes(props.tokenId, (numLiked) => {
        setLikesCount(numLiked)
      })
    }
    if (props.tokenId) {
      checkIfLikedPost()
      getLikesCount()
    }
  }, [props.tokenId, newLike]);

  useEffect(() => {
    if (fakeNewLike) {
      if (likedPost) {
        setLikedPost(false)
        setLikesCount(likesCount - 1)
      }
      else {
        setLikedPost(true)
        setLikesCount(likesCount + 1)
      }
    }
  }, [fakeNewLike]);

  useEffect(() => {
    async function checkIfRepostedPost() {
      hasRepostedPost(account, props.tokenId, (hasReposted) => {
        setRepostedPost(hasReposted)
      })
    }
    async function getRepostsCount() {
      getNumReposts(props.tokenId, (numReposted) => {
        setRepostsCount(numReposted)
      })
    }
    if (props.tokenId) {
      checkIfRepostedPost()
      getRepostsCount()
    }
  }, [props.tokenId, newRepost]);

  useEffect(() => {
    if (fakeNewRepost) {
      if (repostedPost) {
        setRepostsCount(repostsCount - 1)
        setRepostedPost(false)
      }
      else {
        setRepostsCount(repostsCount + 1)
        setRepostedPost(true)
      }
    }
  }, [fakeNewRepost]);

  useEffect(() => {
    async function getRepliesCount() {
      getNumReplies(props.tokenId, (numReplied) => {
        setRepliesCount(numReplied)
      })
    }

    if (props.tokenId) {
      getRepliesCount()
    }
  }, [props.tokenId]);

  useEffect(() => {
    // set content and image if they've been cached
    if (props.tokenId) {
      const img_cache_name = props.tokenId + "img"
      if (shouldUseCache(img_cache_name, 60 * 60 * 60 * 2)) {
        const img = getFromCache(img_cache_name)
        setPostImage(img)
      }

      const text_cache_name = props.tokenId + "text"
      if (shouldUseCache(text_cache_name, 60 * 60 * 60 * 2)) {
        const textArr = getFromCache(text_cache_name)
        setPostTextArr(textArr)
      }
    }
  }, [props.tokenId]);

  useEffect(() => {
    if (props.isNSFW) {
      setIsNSFW(true)
    }
  }, [props.isNSFW])

  useEffect(() => {
    async function getIsFakePost() {
      isPostFake(props.tokenId, (isFake) => {
        setIsFakePost(isFake)
      })
    }

    if (props.tokenId) {
      getIsFakePost()
    }
  }, [props.tokenId, postMinted]);

  useEffect(() => {
    var content = props.content
    setPostImage()
    if (content) {
      if (content.includes(";PostNFT:")) {
        var postContent = content.split(";PostNFT:")[0]

        const nft_info = content.split(";PostNFT:")[1]
        const nft_address = nft_info.split(",")[0]
        const nft_id = nft_info.split(",")[1]
        getImageFromNFTContract(nft_address, nft_id, (img) => {
          setPostImage(img)
          setImageLoading(true)
//          roundCorners(img).then((postImg) => {
//             setPostImage(postImg)
//             const cache_name = props.tokenId + "img"
//             cache(cache_name, postImg)
//          })
        })

        getChainIdFromNFTContract(nft_address, nft_id, (chainId) => {
          setImageNFTChainId(chainId)
        })

        setPostText(postContent)
        if (postContent) {
          setPostTextArr(postContent.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, postContent.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
      else if (content.includes(";IpfsImageHash:")) {
        const postContent = content.split(";IpfsImageHash:")[0]
        const img = "https://ipfs.io/ipfs/" + content.split(";IpfsImageHash:")[1]
        setImageLoading(true)
        setPostImage(img)
        const cache_name = props.tokenId + "img"
        cache(cache_name, img)
//        roundCorners(img).then((postImg) => {
//           setPostImage(postImg)
//           const cache_name = props.tokenId + "img"
//           cache(cache_name, postImg)
//        })
        setPostText(postContent)
        if (postContent) {
          setPostTextArr(postContent.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, postContent.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
      else {
        setPostText(content)
        if (content) {
          setPostTextArr(content.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, content.replaceAll('<br/>', '\n').split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
    }
  }, [props.content]);

  useEffect(() => {
    if (props.reposter) {
      getProfileNameFromAddress(props.reposter, (name) => {
        setReposterName(name)
      })
    }
  }, [props.reposter])

  useEffect(() => {
    if (fakeReplyCounter) {
      setRepliesCount(repliesCount + 1)
    }
  }, [fakeReplyCounter])

  function timeSince(timestamp) {
    var date = new Date(timestamp * 1000);
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "1 month"
      }
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "1 day"
      }
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "1 hour"
      }
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (Math.floor(interval) == 1) {
        return "1 minute"
      }
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const noPropagation = e => e.stopPropagation()

  const handleClickOptions = (e) => {
    setAnchorElOptions(e.currentTarget);
  }

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };

  const showNSFW = (e) => {
    e.stopPropagation()
    setIsNSFW(false)
  }

  const getLink = (l) => {
    var link = l;
    if (link.charAt(0) == '\n') {
      link.substring(1)
    }
    if (!link.includes("http") && !link.includes("ipfs://")) {
      return "https://" + link
    }
    return link
  }

  const getProfileLinkText = (link) => {
    var returnLink = ""
    if (link.charAt(0) == '@') {
      returnLink = "@"
      link = link.substring(1)
    }
    else if (link.charAt(0) == '\n' && link.charAt(1) == '@') {
      returnLink = "\n@"
      link = link.substring(2)
    }
    if (link.includes("0x")) {
      returnLink += link.substring(0,6) + "..." + link.slice(-4)
    }
    else {
      returnLink += link
    }
    return returnLink
  }

  const getProfileLink = (profile) => {
    var link = ""
    if (profile.charAt(0) == '@') {
      link = profile.substring(1)
    }
    else if (profile.charAt(0) == '\n' && profile.charAt(1) == '@') {
      link = profile.substring(2)
    }
    return link
  }

  const RenderWord = ({word}) => {
    if (!word) {
      return (<></>)
    }
    return (
      word.charAt(0) == '\n' ?
        <span><br/>{word} </span>
      :
        <span>{word} </span>
    )
  }

  const FormattedContent = ({words}) => {
    return (
      words.map((word) => (
        word.includes(".io") || word.includes(".com") || word.includes("http") || word.includes("ipfs://") ||
        word.includes(".org") || word.includes(".gov") || word.includes(".ca") || word.includes(".net") ||
        word.includes(".uk") || word.includes(".ro") || word.includes(".co")
        ?
         <span onClick={noPropagation}><a style={{textDecoration: "none"}} target="_blank" href={getLink(word)}>
          <RenderWord word={word} />
         </a></span>
        :
          (word.charAt(0) == "@" || (word.charAt(0) == '\n' && word.charAt(1) == "@")) ?
            <span onClick={noPropagation}><a style={{textDecoration: "none"}} href={"/" + getProfileLink(word)}>
              <RenderWord word={getProfileLinkText(word)} />
            </a></span>
          :
            <RenderWord word={word} />
      ))
    )
  }

  return (
    <React.Fragment>
      <Paper
         ref={myRef}
         elevation={0}
         variant="outlined"
         sx={{ marginTop: '10px', maxWidth: "100%", borderColor: props.borderColor ? props.borderColor : "#ececec", boxShadow: props.boxShadow }}>
        <ListItem button={!props.isMainPost} disableRipple style={{userSelect: 'text'}} onClick={postClick} >
          <Grid container spacing={2}>
            { (reposterName && props.reposter != props.poster) &&
              <Grid item xs={12} container>
                <span style={{ marginTop: "10px", marginLeft: "5px" }}>
                  <Typography component="span" onClick={ (e) => { goToProfile(e, props.reposter) }} style={{ fontSize: "14px", fontWeight: 'bold', paddingRight: "3px" }}>
                    <CachedIcon style={{ width: 15, height: 15, marginTop: -2}}/> {reposterName}
                  </Typography>
                  <Typography component="span" style={{ fontSize: "14px" }}>Reposted</Typography>
                </span>
              </Grid>
            }
            <Grid item xs={12} container>
              <Grid item xs={1.5}>
                <ListItemAvatar sx={{ marginTop: "10px" }} >
                  <ProfileNFT profileAccount={props.poster} widthpx={"40px"}/>
                </ListItemAvatar>
              </Grid>
              <Grid item xs={10}>
                <span onClick={noPropagation}>
                  <IconButton
                   sx={{ float: "right", marginRight: "-20px"}}
                   onClick={(e) => handleClickOptions(e)}
                   id="basic-button"
                   aria-controls="basic-menu"
                   aria-haspopup="true"
                   aria-expanded={openOptions ? 'true' : undefined}
                   >
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElOptions}
                    open={openOptions}
                    onClose={handleCloseOptions}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                   >
                    { (props.poster == account && props.poster == props.owner) &&
                      <span>
                        <MenuItem><FontAwesomeIcon icon={faEthereum} style={{ marginLeft: "5px", marginRight: "15px", color: "#aaa", fontSize: "20px" }} />
                          <span style={{ color: "#aaa" }} >Bridge To Ethereum L1 (Coming Soon) </span>
                        </MenuItem>
                        <MenuItem onClick={deleteNFTPost}><DeleteIcon sx={{ marginRight: "15px", color: "#888" }} /> Delete</MenuItem>
                      </span>
                    }
                    { isFakePost &&
                      <MenuItem onClick={fakeOpenseaClick}><PublishIcon sx={{ marginRight: "15px", color: "#888" }}/>Lazy Mint Post</MenuItem>
                    }
                    <MenuItem onClick={reportPost}><FlagIcon sx={{ marginRight: "15px", color: "#888" }}/> Report</MenuItem>
                  </Menu>
                </span>
                <ListItemText sx={{ display: { xs: 'none', md: 'block' } }} primary={
                  <>
                    <span>
                      <Typography component="span" style={{ fontSize: "16px" }}>Poster: </Typography>
                      <Typography component="span" onClick={ (e) => { goToProfile(e, props.poster) }} style={{ fontSize: "16px", fontWeight: 'bold', cursor: "pointer" }}>{
                        props.name}
                      </Typography></span><span style={{ marginLeft: "10px" }}>
                      <Typography component="span" style={{ fontSize: "16px" }}>Owner:</Typography>
                      <Typography component="span" onClick={ (e) => { goToProfile(e, props.owner) }} style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
                        {props.ownerName}
                      </Typography>
                      <div style={{ color: "#aaa", fontSize: "14px" }} >
                        {timeSince(props.postTimestamp) != "NaN seconds" && timeSince(props.postTimestamp) + " ago"}
                      </div>
                    </span>
                  </>} secondary={<Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                  { !isNSFW &&
                    <FormattedContent words={postTextArr} />
                  }
                </Typography>} />
                <ListItemText sx={{ display: { xs: 'block', md: 'none' } }} primary={
                  <>
                    <div>
                      <Typography component="span" style={{ fontSize: "14px", marginLeft: "10px" }}>Poster: </Typography>
                      <Typography component="span" onClick={ (e) => { goToProfile(e, props.poster) }} style={{ fontSize: "14px", fontWeight: 'bold' }}>
                        {props.name}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Typography component="span" style={{ fontSize: "14px" }}>Owner:</Typography>
                      <Typography component="span" onClick={ (e) => { goToProfile(e, props.owner) }} style={{ fontSize: "14px", fontWeight: "bold", marginLeft: "5px" }}>
                        {props.ownerName}
                      </Typography>
                    </div>
                  </>} secondary={<Typography style={{ fontSize: "16px", marginTop: "10px", marginLeft: "10px" }}>
                  { !isNSFW &&
                    <FormattedContent words={postTextArr} />
                  }
                </Typography>} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              { (imageLoading && !isNSFW ) &&
                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              }
              { (postImage && !isNSFW ) &&
                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                  { imageNFTChainId &&
                    <span onClick={noPropagation}>
                      <IconButton sx={{ color: "#333", position: "absolute", marginLeft: "10px", marginTop: "10px" }}  onClick={ openNFTImage } >
                        <Tooltip title="View NFT on OpenSea" placement="right">
                          <img style={{ width: 22, height: 22 }} src={openseaLogo} />
                        </Tooltip>
                      </IconButton>
                    </span>
                  }
                  <img
                    onClick={ (e) => { openBigImage(e) }}
                    src={postImage}
                    style={{
                      marginTop: "0px",
                      cursor: "pointer",
                      height: "200px",
                      maxWidth: "90%",
                      borderRadius: "10px",
                      objectFit: "cover",
                     }}
                    onLoad={() => setImageLoading(false)}
                   />
                </Box>
              }
              { isNSFW &&
                <>
                  <Box sx={{ display: 'flex', justifyContent: "center"}} >
                    <span style={{ marginTop: "0px" }}>Post marked as NSFW</span>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: "center"}} >
                    <Button onClick={ (e) => { showNSFW(e) }} variant="outlined" sx={{ height: "40px", marginTop: "10px", textTransform: 'none' }} >Show</Button>
                  </Box>
                </>
              }
            </Grid>
            { props.isMainPost &&
              <Grid item xs={12} container spacing={2}>
                <span onClick={noPropagation} style={{ marginTop: "20px", marginLeft: "20px", cursor: "pointer", marginBottom: "10px" }}>
                  <span onClick={openReposters} ><strong style={{ color: "#000" }}>{repostsCount}</strong><span style={{ color: "#444" }}> {repostsCount == 1 ? "Repost" : "Reposts"}</span></span>
                  <span onClick={openUpvoters} style={{ marginLeft: "15px"}} ><strong style={{ color: "#000" }}>{likesCount}</strong><span style={{ color: "#444" }}> {likesCount == 1 ? "Like" : "Likes"}</span></span>
                </span>
              </Grid>
            }
            <Grid item xs={12} container spacing={2} >
              <Grid item xs={2.5}>
                <IconButton sx={{ color: "#333", marginTop: "1px" }} onClick={ (e) => { replyClick(e) }}>
                  <ChatBubbleOutlineOutlinedIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}} />
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{repliesCount ? repliesCount : 0}</label>
              </Grid>
              <Grid item xs={2.5}>
                <IconButton sx={{ color: "#333" }} onClick={ (e) => { repostClick(e, props.tokenId) }}>
                  { repostedPost ?
                    <CachedIcon style={{ width: 20, height: 20, color: "#23a62e"}}/>
                    : <CachedIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                  }
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{repostsCount ? repostsCount: 0}</label>
              </Grid>
              <Grid item xs={2.5}>
                <IconButton sx={{ color: "#333" }} onClick={ (e) => { likeClick(e, props.tokenId) }}>
                  { likedPost ?
                    <FavoriteIcon style={{ width: 20, height: 20, color: "#e34949"}}/>
                    : <FavoriteBorderIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                  }
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{likesCount ? likesCount : 0}</label>
              </Grid>
              <Grid item xs={1.5}>
                { isFakePost
                  ?
                    <IconButton sx={{ color: "#333" }}  onClick={ (e) => { fakeOpenseaClick(e) }} >
                      <img style={{ width: 20, height: 20 }} src={openseaLogoFake} />
                    </IconButton>
                  :
                    <IconButton sx={{ color: "#333" }}  onClick={ (e) => { openseaClick(e, props.tokenId) }} >
                      <img style={{ width: 20, height: 20 }} src={openseaLogo} />
                    </IconButton>
                }

              </Grid>
              <Grid item xs={1.5}>
                <IconButton sx={{ color: "#333" }} onClick={ (e) => { shareClick(e, props.tokenId) }} >
                  <IosShareIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                </IconButton>
              </Grid>
              <Grid item xs={1.5}>
                { twitterShareLoading ?
                  <CircularProgress style={{width: "20px", height: "20px", margin: "10px"}} />
                :
                  <IconButton sx={{ color: "#333" }} onClick={ (e) => { tweet(e, props.tokenId) }} >
                    <TwitterIcon style={{ color: "#1DA1F2", width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                  </IconButton>
                }
              </Grid>
            </Grid>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <div style={{ color: "#aaa", fontSize: "14px", marginLeft: "10px" }} >
                {timeSince(props.postTimestamp) != "NaN seconds" && timeSince(props.postTimestamp) + " ago"}
              </div>
            </Box>
          </Grid>
        </ListItem>
      </Paper>
      <StartedTransactionDialog
        open={openStartedTransactionDialog}
        onClose={handleCloseStartedTransactionDialog}
        link={dialogTransactionLink}
        title={dialogTransactionTitle}
      />
      <LoadingTransactionDialog
        open={openLoadingTransaction}
        onClose={handleCloseLoadingTransaction}
        transactionLink={dialogTransactionLink}
        transactionTitle={dialogTransactionTitle}
      />
      <SuccessDialog
        open={openTransactionConfirmed}
        onClose={handleCloseTransactionConfirmed}
        link={dialogTransactionLink}
        title={dialogTransactionTitle}
      />
      <SimpleSuccessDialog
        open={openSimpleConfirmation}
        onClose={handleCloseSimpleConfirmation}
        title={dialogTransactionTitle}
      />
      <ClipboardDialog
        open={openClipboardDialog}
        onClose={handleCloseClipboardDialog}
        title={clipboardTitle}
      />
      <ReplyDialog
        open={openReplyDialog}
        onClose={handleCloseReplyDialog}
        setFakeReplyCounter={setFakeReplyCounter}
        {...props}
      />
      <BigImageDialog
        open={openBigImageDialog}
        onClose={handleCloseBigImageDialog}
        image={postImage}
      />
      <UpvotersRepostersDialog
        open={openUpvotersReposters}
        onClose={handleCloseUpvotersReposters}
        showUpvoters={shouldOpenUpvoters}
        provider={provider}
        account={account}
        tokenId={props.tokenId}
        count={shouldOpenUpvoters ? likesCount : repostsCount}
      />
      <MintFakePostDialog
        open={openMintFake}
        onClose={handleCloseMintFake}
        provider={provider}
        account={account}
        tokenId={props.tokenId}
        setPostMinted={setPostMinted}
      />
    </React.Fragment>
  );
}

export default Post;


