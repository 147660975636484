import React, { useEffect, useState, useRef } from "react";
import { useHistory, Router, Link } from "react-router-dom";
import {createBrowserHistory} from 'history';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IosShareIcon from '@mui/icons-material/IosShare';
import CachedIcon from '@mui/icons-material/Cached';
import CircularProgress from '@mui/material/CircularProgress';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';
import openseaLogo from '../opensea.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons'

import {  ReplyDialog,
          SuccessDialog,
          SimpleSuccessDialog,
          StartedTransactionDialog,
          LoadingTransactionDialog,
          ClipboardDialog,
          BigImageDialog,
          UpvotersRepostersDialog
       } from "../Dialogs.js"

import {  post_contract_address,
          switchToPolygonTestnet,
          getProfileNameFromAddress,
          getImageFromNFTContract,
          getChainIdFromNFTContract,
          getImageFromNFT
       } from "../ContractHelpers.js"

import {  hasLikedPost,
          hasRepostedPost,
          waitForUpvoteTransaction,
          waitForRemoveUpvoteTransaction,
          waitForRepostTransaction,
          waitForRemoveRepostTransaction,
          getNumLikes,
          getNumReposts,
          getNumReplies,
          waitForDeletePostTransaction
        } from "../FirebaseRetrieval.js"

import { roundCorners, toDataURL } from "../Utils/CanvasUtils.js"
import ProfileNFT from "../Profile/Avatar.js"
import { getFromCache, shouldUseCache, cache } from "../Cache.js"

const sigUtil = require('eth-sig-util');
const ethUtil = require('ethereumjs-util');
const ethers = require('ethers');

const FakePost = (props) => {
  const account = props.account
  const provider = props.provider

  let history = useHistory();
  const [openReplyDialog, setOpenReplyDialog] = useState(false)
  const [openBigImageDialog, setOpenBigImageDialog] = useState(false)
  const [dialogTransactionLink, setDialogTransactionLink] = useState();
  const [dialogTransactionTitle, setDialogTransactionTitle] = useState();
  const [openStartedTransactionDialog, setOpenStartedTransactionDialog] = useState(false);
  const [openTransactionConfirmed, setOpenTransactionConfirmed] = useState(false);
  const [openLoadingTransaction, setOpenLoadingTransaction] = useState(false);
  const [openSimpleConfirmation, setOpenSimpleConfirmation] = useState(false)

  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const openOptions = Boolean(anchorElOptions);

  const [likesCount, setLikesCount] = useState(35)
  const [repostsCount, setRepostsCount] = useState(3)
  const [repliesCount, setRepliesCount] = useState(2)

  const [postImage, setPostImage] = useState()
  const [postText, setPostText] = useState()
  const [postTextArr, setPostTextArr] = useState([])
  const [imageLoading, setImageLoading] = useState(false)
  const [imageNFTChainId, setImageNFTChainId] = useState()

  const [isNSFW, setIsNSFW] = useState(false)

  const [openUpvotersReposters, setOpenUpvotersReposter] = useState(false)
  const [shouldOpenUpvoters, setShouldOpenUpvoters] = useState(false)

  const [twitterShareLoading, setTwitterShareLoading] = useState(false)

  const [clipboardTitle, setClipboardTitle] = useState("Link copied to clipboard")
  const [openClipboardDialog, setOpenClipboardDialog] = useState(false)

  const [elev, setElev] = React.useState(10);

  let openNFTImage = () => {
    const nft_info = props.content.split(";PostNFT:")[1]
    const nft_contract_addr = nft_info.split(",")[0]
    const nft_tokenid = nft_info.split(",")[1]

    var opensea_url = "https://opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
    if (imageNFTChainId == 137) {
        opensea_url = "https://opensea.io/assets/matic/" + nft_contract_addr + "/" + nft_tokenid;
    }
    else if (imageNFTChainId == 4) {
      opensea_url = "https://testnets.opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
    }
    window.open(opensea_url)
  }

  let tweet = async(e, tokenId) => {
    e.stopPropagation();
    setTwitterShareLoading(true)
    var content = props.content
    if (content) {
      if (content.includes(";PostNFT:")) {
        var postContent = content.split(";PostNFT:")[0]
        postContent = postContent.replace(/\n/g, "%0A")

        const nft_info = content.split(";PostNFT:")[1]
        const nft_address = nft_info.split(",")[0]
        const nft_id = nft_info.split(",")[1]
        getImageFromNFTContract(nft_address, nft_id, async(img) => {
          var text = "https://twitter.com/intent/tweet?text=" + postContent
          text += "%0A%0ANFT: bitprofile.io/post/" + tokenId

          var messageForServer = {
            imageURL: img
          }
          const messageJSON = JSON.stringify(messageForServer);
          var messageBuff = new Buffer(messageJSON);
          var encodedMessage = messageBuff.toString('base64');
          const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
          const response = await fetch(url);
          const data = await response.json();

          if (data.status == "success") {
            const respData = data.data
            const id = respData["id"].toString()
            const twitterPic = respData["entities"]["media"][0]["display_url"]
            text += "%0A%0A&url=" + encodeURI(twitterPic)
            setTwitterShareLoading(false)
            window.open(text, '_blank')
          }
          else {
            // This gets the image from OpenSea or web3 and NOT from BitProfile.
            try {
              getImageFromNFT(nft_address, nft_id, false, (imgUrl) => {
                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
                setTwitterShareLoading(false)
                window.open(text, '_blank')
              })
            }
            catch {
              getImageFromNFT(nft_address, nft_id, true, (imgUrl) => {
                text += "%0A%0A[Message for tweeter... upload this image manually: " + imgUrl + "]"
                setTwitterShareLoading(false)
                window.open(text, '_blank')
              })
            }
          }
        })
      }
      else if (content.includes(";IpfsImageHash:")) {
        var postContent = content.split(";IpfsImageHash:")[0]
        postContent = postContent.replace(/\n/g, "%0A")

        const img = "https://ipfs.io/ipfs/" + content.split(";IpfsImageHash:")[1]

        var text = "https://twitter.com/intent/tweet?text=" + postContent
        text += "%0A%0ANFT: bitprofile.io/post/" + tokenId

        var messageForServer = {
          imageURL: img
        }
        const messageJSON = JSON.stringify(messageForServer);
        var messageBuff = new Buffer(messageJSON);
        var encodedMessage = messageBuff.toString('base64');
        const url = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/getTwitterImage?params=" + encodedMessage
        const response = await fetch(url);
        const data = await response.json();

        if (data.status == "success") {
          const respData = data.data
          const id = respData["id"].toString()
          const twitterPic = respData["entities"]["media"][0]["display_url"]
          text += "%0A%0A&url=" + encodeURI(twitterPic)
        }
        else {
          text += "%0A%0A[Message for tweeter... upload this image manually: " + img + "]"
        }
        setTwitterShareLoading(false)
        window.open(text, '_blank')
      }
      else {
        content = content.replace(/\n/g, "%0A")
        console.log(content)
        var text = "https://twitter.com/intent/tweet?text=" + content
        text += "%0A%0ANFT: bitprofile.io/post/" + tokenId
        setTwitterShareLoading(false)
        window.open(text, '_blank')
      }
    }
  }


  let openseaClick = (e, tokenId) => {
    e.stopPropagation();
    window.open("https://testnets.opensea.io/assets/mumbai/" + post_contract_address + "/" + tokenId, '_blank')
  }

  const openBigImage = (e) => {
    e.stopPropagation();
    setOpenBigImageDialog(true)
  }

  const handleCloseBigImageDialog = (value) => {
    setOpenBigImageDialog(false)
  }

  useEffect(() => {
    var content = props.content
    setPostImage()
    if (content) {
      if (content.includes(";PostNFT:")) {
        var postContent = content.split(";PostNFT:")[0]
        postContent = postContent.replace(/\n/g, "%0A")

        const nft_info = content.split(";PostNFT:")[1]
        const nft_address = nft_info.split(",")[0]
        const nft_id = nft_info.split(",")[1]
        getImageFromNFTContract(nft_address, nft_id, (img) => {
          console.log(img)
          setImageLoading(true)
          roundCorners(img).then((postImg) => {
             setPostImage(postImg)
             const cache_name = props.tokenId + "img"
             cache(cache_name, postImg)
          })
        })

        getChainIdFromNFTContract(nft_address, nft_id, (chainId) => {
          setImageNFTChainId(chainId)
        })

        setPostText(postContent)
        if (postContent) {
          setPostTextArr(postContent.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, postContent.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
      else if (content.includes(";IpfsImageHash:")) {
        const postContent = content.split(";IpfsImageHash:")[0]
        const img = "https://ipfs.io/ipfs/" + content.split(";IpfsImageHash:")[1]
        setImageLoading(true)
        roundCorners(img).then((postImg) => {
           setPostImage(postImg)
           const cache_name = props.tokenId + "img"
           cache(cache_name, postImg)
        })
        setPostText(postContent)
        if (postContent) {
          setPostTextArr(postContent.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, postContent.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
      else {
        setPostText(content)
        if (content) {
          setPostTextArr(content.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))

          const cache_name = props.tokenId + "text"
          cache(cache_name, content.split(' ').join(',*,').split(/(?=\n)/g).join(',*,').split(',*,'))
        }
      }
    }
  }, [props.content]);

  function timeSince(timestamp) {
    var date = new Date(timestamp * 1000);
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const noPropagation = e => e.stopPropagation()

  const handleClickOptions = (e) => {
    setAnchorElOptions(e.currentTarget);
  }

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };

  const getLink = (l) => {
    var link = l;
    if (link.charAt(0) == '\n') {
      link.substring(1)
    }
    if (!link.includes("http") && !link.includes("ipfs://")) {
      return "https://" + link
    }
    return link
  }

  const getProfileLinkText = (link) => {
    var returnLink = ""
    if (link.charAt(0) == '@') {
      returnLink = "@"
      link = link.substring(1)
    }
    else if (link.charAt(0) == '\n' && link.charAt(1) == '@') {
      returnLink = "\n@"
      link = link.substring(2)
    }
    if (link.includes("0x")) {
      returnLink += link.substring(0,6) + "..." + link.slice(-4)
    }
    else {
      returnLink += link
    }
    return returnLink
  }

  const getProfileLink = (profile) => {
    var link = ""
    if (profile.charAt(0) == '@') {
      link = profile.substring(1)
    }
    else if (profile.charAt(0) == '\n' && profile.charAt(1) == '@') {
      link = profile.substring(2)
    }
    return link
  }

  const RenderWord = ({word}) => {
    if (!word) {
      return (<></>)
    }
    return (
      word.charAt(0) == '\n' ?
        <span><br/>{word} </span>
      :
        <span>{word} </span>
    )
  }

  const FormattedContent = ({words}) => {
    return (
      words.map((word) => (
        word.includes(".io") || word.includes(".com") || word.includes("http") || word.includes("ipfs://") ||
        word.includes(".org") || word.includes(".gov") || word.includes(".ca") || word.includes(".net") ||
        word.includes(".uk") || word.includes(".ro") || word.includes(".co")
        ?
         <span onClick={noPropagation}><a style={{textDecoration: "none"}} target="_blank" href={getLink(word)}>
          <RenderWord word={word} />
         </a></span>
        :
          (word.charAt(0) == "@" || (word.charAt(0) == '\n' && word.charAt(1) == "@")) ?
            <span onClick={noPropagation}><a style={{textDecoration: "none"}} href={"/" + getProfileLink(word)}>
              <RenderWord word={getProfileLinkText(word)} />
            </a></span>
          :
            <RenderWord word={word} />
      ))
    )
  }

  return (
    <React.Fragment>
      <Paper
       elevation={elev}
       onMouseOver={() => setElev(18)}
       onMouseOut={() => setElev(10)}
       sx={{ marginTop: '10px', maxWidth: "100%", borderRadius: "15px", boxShadow: "0px 0px 20px 5px #ffffff60" }}>
        <ListItem style={{userSelect: 'text', backgroundColor: "#FFF", borderRadius: "15px"}} >
          <Grid container spacing={1}>
            <Grid item xs={12} container>
              <Grid item xs={1.5}>
                <ListItemAvatar sx={{ marginTop: "10px" }} >
                  <ProfileNFT profileAccount={"0xB112289f5dC20e57d543912DbD4cD68df2928FdA"} widthpx={"40px"}/>
                </ListItemAvatar>
              </Grid>
              <Grid item xs={10}>
                <ListItemText sx={{ display: { xs: 'none', md: 'block' } }} primary={
                  <>
                    <span>
                      <Typography component="span" style={{ fontSize: "16px" }}>Poster: </Typography>
                      <Typography component="span" style={{ fontSize: "16px", fontWeight: 'bold', cursor: "pointer" }}>{
                        props.name}
                      </Typography></span><span style={{ marginLeft: "10px" }}>
                      <Typography component="span" style={{ fontSize: "16px" }}>Owner:</Typography>
                      <Typography component="span" style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>
                        {props.ownerName}
                      </Typography>
                      <div style={{ color: "#aaa", fontSize: "14px" }} >
                        1 week ago
                      </div>
                    </span>
                  </>} secondary={<Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                  <FormattedContent words={postTextArr} />
                </Typography>} />
                <ListItemText sx={{ display: { xs: 'block', md: 'none' } }} primary={
                  <>
                    <span>
                      <Typography component="span" style={{ fontSize: "12px", marginLeft: "10px" }}>Poster: </Typography>
                      <Typography component="span" style={{ fontSize: "12px", fontWeight: 'bold' }}>
                        {props.name}
                      </Typography>
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      <Typography component="span" style={{ fontSize: "12px" }}>Owner:</Typography>
                      <Typography component="span" style={{ fontSize: "12px", fontWeight: "bold", marginLeft: "5px" }}>
                        {props.ownerName}
                      </Typography>
                    </span>
                  </>} secondary={<Typography style={{ fontSize: "14px", marginTop: "5px", marginLeft: "10px" }}>
                  <FormattedContent words={postTextArr} />
                </Typography>} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              { imageLoading &&
                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              }
              { postImage &&
                <Box sx={{ display: 'flex', justifyContent: "center" }}>
                  { imageNFTChainId &&
                    <span onClick={noPropagation}>
                      <IconButton sx={{ color: "#333", position: "absolute", marginLeft: "10px", marginTop: "10px" }}  onClick={ openNFTImage } >
                        <Tooltip title="View NFT on OpenSea" placement="right">
                          <img style={{ width: 22, height: 22 }} src={openseaLogo} />
                        </Tooltip>
                      </IconButton>
                    </span>
                  }
                  <img
                    onClick={ (e) => { openBigImage(e) }}
                    src={postImage}
                    style={{marginTop: "0px", cursor: "pointer", height: "200px", maxWidth: "90%", objectFit: "contain"}}
                    onLoad={() => setImageLoading(false)}
                   />
                </Box>
              }
            </Grid>
            <Grid item xs={12} container spacing={2} >
              <Grid item xs={3}>
                <IconButton sx={{ color: "#333", marginTop: "1px" }}>
                  <ChatBubbleOutlineOutlinedIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}} />
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{repliesCount ? repliesCount : 0}</label>
              </Grid>
              <Grid item xs={3}>
                <IconButton sx={{ color: "#333" }}>
                  <CachedIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{repostsCount ? repostsCount: 0}</label>
              </Grid>
              <Grid item xs={3}>
                <IconButton sx={{ color: "#333" }}>
                  <FavoriteBorderIcon style={{ width: 20, height: 20, stroke: "#fff", strokeWidth: "0.5px"}}/>
                </IconButton>
                <label style={{ fontSize: "14px", marginLeft: "7px" }}>{likesCount ? likesCount : 0}</label>
              </Grid>
              <Grid item xs={3}>
                <IconButton sx={{ color: "#333" }} onClick={ (e) => { openseaClick(e, props.tokenId) }}  >
                  <img style={{ width: 20, height: 20 }} src={openseaLogo} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </Paper>
      <BigImageDialog
        open={openBigImageDialog}
        onClose={handleCloseBigImageDialog}
        image={postImage}
      />
    </React.Fragment>
  );
}

export default FakePost;

