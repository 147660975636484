import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from 'react-router-dom'

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Post from "../Post/Post.js"
import CreatePost from "../Post/CreatePost.js"
import { getPostsOfAddress, getNextPostsOfAddress, getPostData, getCollectedPostsOfAddress, getNextCollectedPostsOfAddress } from "../FirebaseRetrieval.js"

import { getFromCache, shouldUseCache, cache } from "../Cache.js"

const Web3 = require('web3');

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const scrollToPosition = (y) => {
  const isMobile = (window.innerHeight / window.innerWidth) > 1
  if (isMobile) {
    window.scroll(0, y)
  }
  else {
    document.getElementById("list").scroll(0, y)
  }
}

var postsSet = new Set()

function ProfileFeed({ profileAccount, account, provider, fullPagesScrolled }) {
  const [fetchedFeedPosts, setFetchedFeedPosts] = useState(false)
  const [isFetchingNextPosts, setIsFetchingNextPosts] = useState(false)
  const [isLoadingFromCache, setIsLoadingFromCache] = useState(false)
  const [newPost, setNewPost] = useState();
  const [postDeleted, setPostDeleted] = useState();
  const [profilePosts, setProfilePosts] = useState([]);
  const [lastPostTimestamp, setLastPostTimestamp] = useState(0)

  const location = useLocation();

  async function fetchPostsDataFirebase(posts, deleted, cb) {
    if (posts.length == 1) {
      getPostData(posts[0].key, (postDict) => {
        try {
          postDict["feedTimestamp"] = posts[0].timestamp;
          if (posts[0].poster) {
            postDict["poster"] = posts[0].poster
          }
          if (posts[0].reposter) {
            postDict["reposter"] = posts[0].reposter
          }
          if (postsSet.has(postDict.tokenId)) {
            cb([])
          }
          else {
            postsSet.add(postDict.tokenId)
            cb([postDict])
          }
        }
        catch {
          deleted["found"] = true
          cb([])
        }
      })
    }
    else {
      // get post data for latest post
      getPostData(posts[0].key, (postDict) => {
        try { // Deleted posts will fail
          postDict["feedTimestamp"] = posts[0].timestamp;
          if (posts[0].poster) {
            postDict["poster"] = posts[0].poster
          }
          if (posts[0].reposter) {
            postDict["reposter"] = posts[0].reposter
          }
          var poppedPosts = [...posts];
          poppedPosts.shift();
          fetchPostsDataFirebase(poppedPosts, deleted, (postsArr) => {
            if (!postsSet.has(postDict.tokenId)) {
              postsArr.push(postDict)
              postsSet.add(postDict.tokenId)
            }
            cb(postsArr)
          })
        }
        catch {
          var poppedPosts = [...posts];
          poppedPosts.shift();
          deleted["found"] = true
          fetchPostsDataFirebase(poppedPosts, deleted, (postsArr) => {
            cb(postsArr)
          })
        }
      })
    }
  }

  function fetchNextPosts() {
    const earliestPostTimestamp = profilePosts[profilePosts.length-1].postTimestamp
    if (earliestPostTimestamp == lastPostTimestamp) {
      return;
    }
    setIsFetchingNextPosts(true)
    getNextPostsOfAddress(profileAccount, earliestPostTimestamp, (posts) => {
      setLastPostTimestamp(earliestPostTimestamp)
      if (posts.length == 0 ) {
        setIsFetchingNextPosts(false)
        return
      }
      var deleted = {found: false}
      fetchPostsDataFirebase(posts, deleted, (postsArr) => {
        postsArr.sort((a, b) => {
            return b.feedTimestamp - a.feedTimestamp;
        });
        setIsFetchingNextPosts(false)
        setProfilePosts(profilePosts => [...profilePosts].concat(postsArr));
        cache("profilePosts", [...profilePosts].concat(postsArr));
//        if (deleted.found) {
//          // If there is a deleted post, fetch the next posts
//          fetchNextPosts()
//        }
      })
    })
  }

  function fetchNextCollectedPosts() {
    const earliestPostTimestamp = profilePosts[profilePosts.length-1].postTimestamp
    if (earliestPostTimestamp == lastPostTimestamp) {
      return;
    }
    setIsFetchingNextPosts(true)
    getNextCollectedPostsOfAddress(profileAccount, earliestPostTimestamp, (posts) => {
      setLastPostTimestamp(earliestPostTimestamp)
      if (posts.length == 0 ) {
        setIsFetchingNextPosts(false)
        return
      }
      var deleted = {found: false}
      fetchPostsDataFirebase(posts, deleted, (postsArr) => {
        postsArr.sort((a, b) => {
            return b.feedTimestamp - a.feedTimestamp;
        });
        setIsFetchingNextPosts(false)
        setProfilePosts(profilePosts => [...profilePosts].concat(postsArr));
        cache("profilePosts", [...profilePosts].concat(postsArr));
      })
    })
  }

  useEffect(() => {
    if (fullPagesScrolled > 0 && profilePosts.length > 0) {
      if (location.pathname.includes("collected")) {
        fetchNextCollectedPosts()
      }
      else {
        fetchNextPosts()
      }
    }
  }, [fullPagesScrolled]);

  useEffect(() => {
    async function fetchProfilePosts(profileAccount) {
      getPostsOfAddress(profileAccount, (posts) => {
        if (posts.length == 0 ) {
          setFetchedFeedPosts(true)
          return
        }
        var deleted = {found: false}
        fetchPostsDataFirebase(posts, deleted, (postsArr) => {
          postsArr.sort((a, b) => {
            return b.feedTimestamp - a.feedTimestamp;
          });
          setProfilePosts(postsArr)
          setFetchedFeedPosts(true)
//          if (deleted.found) {
//            // If there is a deleted post, fetch the next posts
//            fetchNextPosts()
//          }
          cache("profilePosts", postsArr)
        })
      })
    }
    async function fetchCollectedPosts(profileAccount) {
      getCollectedPostsOfAddress(profileAccount, (posts) => {
        if (posts.length == 0 ) {
          setFetchedFeedPosts(true)
          return
        }
        var deleted = {found: false}
        fetchPostsDataFirebase(posts, deleted, (postsArr) => {
          postsArr.sort((a, b) => {
            return b.feedTimestamp - a.feedTimestamp;
          });
          setProfilePosts(postsArr)
          setFetchedFeedPosts(true)
          cache("profilePosts", postsArr)
        })
      })
    }

    if (profileAccount) {
      setProfilePosts([])
      setFetchedFeedPosts(false)
      postsSet = new Set()

      var cache_name = "profilePosts"
      if (shouldUseCache(cache_name, 60 * 60 * 24)) {
        const cachedArr = getFromCache(cache_name)

        if (cachedArr.length > 0) {
          setProfilePosts(cachedArr)
          setFetchedFeedPosts(true)

          var cache_name = "profile_feed_pos"
          if (shouldUseCache(cache_name, 60 * 60 * 60 * 2)) {
            const pos = getFromCache(cache_name)
            if (pos > window.screen.height/2) {
              setIsLoadingFromCache(true)
              setTimeout(() => {
                setIsLoadingFromCache(false)
                scrollToPosition(pos)
              }, 4000)
            }
          }
        }
        else {
          if (location.pathname.includes("/collected")) {
            fetchCollectedPosts(profileAccount)
          }
          else {
            fetchProfilePosts(profileAccount);
          }
        }
      }
      else {
        if (location.pathname.includes("/collected")) {
          fetchCollectedPosts(profileAccount)
        }
        else {
          fetchProfilePosts(profileAccount);
        }
      }
    }
  }, [profileAccount, newPost, postDeleted]);

  const collectedPressed = () => {
//    clearFeedCache()
    window.location.href = location.pathname + "/collected"
  }

  const postedPressed = () => {
    window.location.href = location.pathname.replace("/collected", "")
  }

  return (
    <React.Fragment>
      <Paper square elevation={0}  sx={{ pb: '50px', width: 500, maxWidth: "100%", margin: 'auto'}}>
        { (profileAccount == account && profileAccount != "")  &&
          <CreatePost provider={provider} account={account} parentPostId={0} setNewPost={setNewPost} marginLeft={"-5px"} />
        }
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "15px" }}>
          <Button onClick={postedPressed} style={{textTransform: 'none', marginRight: "10px", width: "120px"}} size="large" variant={ location.pathname.includes("/collected") ? "text" : "outlined" }>Posted</Button>
          <Button onClick={collectedPressed} style={{textTransform: 'none', marginLeft: "10px", width: "120px"}} size="large" variant={ location.pathname.includes("/collected") ? "outlined" : "text" }>Collected</Button>
        </div>
        { isLoadingFromCache &&
          <LinearProgress sx={{ width: '100%', marginTop: "20px" }} />
        }
        { !fetchedFeedPosts ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "75px" }}>
            <CircularProgress />
            <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
          </div>
        :
          <List sx={{ mb: 2, mt: 1, ml: 1, mr: 1 }}>
            {profilePosts.map(({ tokenId, poster, name, content, postTimestamp, chain, owner, ownerName, reposter, isNSFW }) => (
              <React.Fragment key={tokenId}>
                <Post
                  account={account}
                  provider={provider}
                  tokenId={tokenId}
                  poster={poster}
                  name={name}
                  owner={owner}
                  ownerName={ownerName}
                  content={content}
                  isNSFW={isNSFW}
                  postTimestamp={postTimestamp}
                  chain={chain}
                  reposter={reposter}
                  posCacheName={"profile_feed_pos"}
                  setPostDeleted={setPostDeleted}
                />
              </React.Fragment>
            ))}
            { isFetchingNextPosts &&
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "50px" }}>
                  <CircularProgress />
                </div>
                <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
              </>
            }
            <div style={{height: "50vh"}} ></div> {/* padding at bottom */}
          </List>
        }
      </Paper>
    </React.Fragment>
  );
}

export default ProfileFeed;




