import React, { useEffect, useState } from "react";
import Home from './Home/Home.js';
import Profile from './Profile/Profile.js';
import Landing from './Landing/Landing.js';
import PostPage from './Post/PostPage.js';
import NotificationsPage from './Notifications/Notifications.js';
import TopAppBar from './TopNavBar.js'
import { cache } from "./Cache.js"

import { createBrowserHistory } from "history";

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import ExploreIcon from '@mui/icons-material/Explore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccountIcon from '@mui/icons-material/Face';
import SearchIcon from '@mui/icons-material/Search';
import Paper from '@mui/material/Paper';

import "./App.css";
import logo from './icon.png';

import { Nav, NavItem} from 'reactstrap';
import { Router, Switch, Route, NavLink, Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { Body, Button, Header, Image } from "./components";
import useWeb3Modal from "./hooks/useWeb3Modal";

var ethers = require('ethers');
var crypto = require('crypto');
const Web3 = require('web3');

// Auctions contract (external to any BitProfile contract)
// https://github.com/avolabs-io/nft-auction/tree/master/contracts

// TODO
// Load relay accounts and start using them
// Balance tracker for relays
// All: Error messages and handling
// Intro page with explanations and link to install metamask. Focus on saying "web3" instead of "crypto"
// Cache profile feed
// NSFW filter: https://github.com/infinitered/nsfwjs
// People might send upvotes of posts twice if they don't see it update. So we need to block that.
//  which means having an entree that when sending transaction, it gives info on what is
//  happening and whether it was completed or not. Or could just delete it and see if it's still there.
//  This would be something like "pendingUpvote" in database. It would fill that in until it gets removed
//  when the real one gets in. But what if transaction fails?

// TODO with update profile stuff
// Before connecting avatar in frontend, check to see if they own it.
// updateNFTAvatarRinkeby firebase function needs to be updated for this new verison.
// Include twitter connection as separate transaction that appears when you click the use twitter username button. This dismisses
//  the connect profile modal.
// Make verified work with new contract
// All new contracts because modified BitProfileInfo.sol and its dependencies. AKA need to put new bitprofileinfo everywhere
//  esp in firebase functions

// TODO MARKETING:
// Go after all punks and bored apes. Specifically those without many followers. Still have to go for those with a lot but
//  those will be more difficult. THe small ones might like being given a voice like this due to the status of their NFT. It
//  could be a chance for them to grow more of a following due to their NFT. So an opportunity and a cool new utility for their NFT.

// TODO LATER:
// Add recaptcha for extra verification... if that doesn't work then eventually phone number to use for free. https://docs.hcaptcha.com/
// When hit transaction limit or error, ask if they want to do transaction manually. (Maybe later)
// Pin posts
// Cache post data
// Repost doesn't show success popup just started popup
// Have owned section in profile. could have a coming soon too. Have filters for external and bitprofile nfts and all.
// Likes in profile
// Could have global posts filter by:
//    Highest purchase price, most likes, hot, recent, and with today, this week, this month, this year, all
//    Could create incentive for people to buy NFTs more.

const tabs = [{
  route: "/",
  icon: faHome,
  label: "Home"
},{
  route: "/notifications",
  icon: faSearch,
  label: "Notifications"
},{
  route: "/profile",
  icon: faUserCircle,
  label: "Profile"
}]

const history = createBrowserHistory();

function App() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  const [pageIndex, setPageIndex] = React.useState(0);

  useEffect(() => {
    const path = history.location.pathname;
    console.log(path)
    if (path == "/") {
      setPageIndex(0)
    }
    else if (path == "/notifications") {
      clearHomeFeedCache()
      setPageIndex(1)
    }
    else if (path == "/settings") {
      clearHomeFeedCache()
      setPageIndex(3)
    }
    else if (path == "/profile") {
      clearHomeFeedCache()
      setPageIndex(2)
    }
    else {
      setPageIndex(-1)
    }
  }, [history.location.pathname]);

  function clearHomeFeedCache() {
    cache("home_feed_pos", 0)
    localStorage.removeItem("feedPosts")
    localStorage.removeItem("globalPosts")
  }

  useEffect(() => {
    // on page reload, clear cache for positions
    clearHomeFeedCache()
  }, [])

  return (
      <Router history={history}>
        <Switch>
          <Route path="*">
            <Landing provider={provider} />
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
