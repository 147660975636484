import {  getInfoContract,
          info_contract_address,
          getPostContract,
          post_contract_address,
          getNetworkContract,
          network_contract_address,
          network_chain_id,
       } from "./ContractHelpers.js"

const Web3 = require('web3');

export const formatUpdateAllTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)

  var user = "";
  var bio = "";
  var website = "";
  var ipfsHash = "";
  var bitProfileNonce = 1;

  user = params.user;

  if (params.bio) {
    bio = params.bio;
  }

  if (params.website) {
    website = params.website
  }

  if (params.ipfsHash) {
    ipfsHash = params.ipfsHash
  }

  bitProfileNonce = params.nonce

  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;

  const contract = getInfoContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var updateAllFromSignature = contract.methods.updateAll(user, bio, website, ipfsHash, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": info_contract_address, "from": address, "data": updateAllFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': info_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': updateAllFromSignature
        };

        cb(transaction)
      })
    })
  })
}

export const formatMintTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const seed = params.seed;
  const poster = params.poster;
  const content = params.content;
  const parentPostId = params.parentPostId;
  const tags = params.tags;
  const metadataURI = params.metadataURI;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var mintPostFromSignature = contract.methods.mintPost(poster, content, metadataURI, parentPostId, tags, seed, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": mintPostFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit ,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': mintPostFromSignature
        };
        cb(transaction)
      })
    })
  })
}


export const formatFollowTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const follower = params.follower;
  const addressToFollow = params.addressToFollow;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getNetworkContract();

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var followFromSignature = contract.methods.follow(follower, addressToFollow, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": network_contract_address, "from": address, "data": followFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': network_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': followFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatUnfollowTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const unfollower = params.unfollower;
  const addressToUnfollow = params.addressToUnfollow;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getNetworkContract();

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
			var unfollowFromSignature = contract.methods.unfollow(unfollower, addressToUnfollow, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": network_contract_address, "from": address, "data": unfollowFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': network_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': unfollowFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatUpvoteTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const seed = params.seed;
  const upvoter = params.upvoter;
  const tokenId = params.tokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var upvotePostFromSignature = contract.methods.upvote(upvoter, tokenId, seed, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": upvotePostFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': upvotePostFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatRemoveUpvoteTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const seed = params.seed;
  const upvoter = params.upvoter;
  const tokenId = params.tokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var removeUpvoteFromSignature = contract.methods.removeUpvote(upvoter, tokenId, seed, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": removeUpvoteFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': removeUpvoteFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatRepostTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const seed = params.seed;
  const reposter = params.reposter;
  const tokenId = params.tokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var repostFromSignature = contract.methods.repost(reposter, tokenId, seed, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": repostFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': repostFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatRemoveRepostTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const seed = params.seed;
  const reposter = params.reposter;
  const tokenId = params.tokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var removeRepostFromSignature = contract.methods.removeRepost(reposter, tokenId, seed, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": removeRepostFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': removeRepostFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatDeletePostTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const poster = params.poster;
  const tokenId = params.tokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getPostContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var deletePostFromSignature = contract.methods.deletePost(poster, tokenId, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": post_contract_address, "from": address, "data": deletePostFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': post_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': deletePostFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatVerifyTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const user = params.user;
  const linkToVerifiedSource = params.linkToVerifiedSource;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getInfoContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var verifyFromSignature = contract.methods.verify(user, linkToVerifiedSource, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": info_contract_address, "from": address, "data": verifyFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': info_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': verifyFromSignature
        };
        cb(transaction)
      })
    })
  })
}


export const formatVerifyAndSetUsernameTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)

  const bitProfileNonce = params.nonce;
  const user = params.user;
  const linkToVerifiedSource = params.linkToVerifiedSource;
  const useVerifiedSourceUsername = params.useVerifiedSourceUsername;

  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getInfoContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
        var verifyFromSignature = contract.methods.verifyAndSetSourceUsername(user, linkToVerifiedSource, useVerifiedSourceUsername, bitProfileNonce, r, s, v).encodeABI();
        var est = web3.eth.estimateGas({"to": info_contract_address, "from": address, "data": verifyFromSignature})
        est.then(function(gasAmount){
          const transaction = {
          'from': address,
          'to': info_contract_address,
          'gas': gasAmount,
          'gasLimit': web3.eth.getBlock("latest").gasLimit,
          'nonce': nonce,
          'gasPrice': gasPrice,
          'chainId': network_chain_id,
          'data': verifyFromSignature
          };
          cb(transaction)
        })
    })
  })
}

export const formatChangeUsernameTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const user = params.user;
  const useVerifiedSourceUsername = params.useVerifiedSourceUsername;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getInfoContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var useUsernameFromSignature = contract.methods.setUseVerifiedSourceUsername(user, useVerifiedSourceUsername, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": info_contract_address, "from": address, "data": useUsernameFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': info_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit * 2,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': useUsernameFromSignature
        };
        cb(transaction)
      })
    })
  })
}

export const formatConnectAvatarTransaction = async (provider, account, transactionParams, cb) => {
  var web3 = new Web3(provider.provider)

  var paramsEncoded = Buffer.from(transactionParams, 'base64')
  const paramsString = paramsEncoded.toString();
  const params = JSON.parse(paramsString)
  const bitProfileNonce = params.nonce;
  const user = params.user;
  const externalNFTChainId = params.externalNFTChainId;
  const externalNftContractAddress = params.externalNftContractAddress;
  const externalTokenId = params.externalTokenId;
  const r = params.r;
  const s = params.s;
  const v = params.v;

  const address = account;
  const contract = getInfoContract()

  web3.eth.getTransactionCount(address, 'pending').then(function(_nonce) {
    var nonce = _nonce;
    web3.eth.getGasPrice().then(function(gasPrice) {
      var connectAvatarNFTFromSignature = contract.methods.connectAvatarNFT(user, externalNFTChainId, externalNftContractAddress, externalTokenId, bitProfileNonce, r, s, v).encodeABI();
      var est = web3.eth.estimateGas({"to": info_contract_address, "from": address, "data": connectAvatarNFTFromSignature})
      est.then(function(gasAmount){
        const transaction = {
        'from': address,
        'to': info_contract_address,
        'gas': gasAmount,
        'gasLimit': web3.eth.getBlock("latest").gasLimit,
        'nonce': nonce,
        'gasPrice': gasPrice,
        'chainId': network_chain_id,
        'data': connectAvatarNFTFromSignature
        };
        cb(transaction)
      })
    })
  })
}











