import React, { useEffect, useState } from "react";
import { styles } from "./Profile.module.css";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { getProfileNFT, getNFTImageURL, waitForFollowTransaction, waitForUnfollowTransaction, isFollowingAddress } from "../FirebaseRetrieval.js"
import { followAddress, unfollowAddress, blockExplorerTx } from "../ContractHelpers.js"
import { formatFollowTransaction, formatUnfollowTransaction } from "../ManualTransactions.js"
import { StartedTransactionDialog, SuccessDialog } from "../Dialogs.js"
import { getFromCache, cache, shouldUseCache } from "../Cache.js"
const { soliditySha3 } = require("web3-utils");
const Web3 = require('web3');

function FollowButton({ profileAccount, provider, account, style, setFollowUpdated }) {

  const [isFollowing, setIsFollowing] = useState();
  const [openStartedTransaction, setOpenStartedTransaction] = useState(false);
  const [openSuccessUpdateTransaction, setOpenSuccessUpdateTransaction] = useState(false);
  const [dialogTransactionLink, setDialogTransactionLink] = useState("")
  const [dialogTitle, setDialogTitle] = useState("")

  const [preppingTransaction, setPreppingTransaction] = useState(false)

  const handleCloseStartedTransaction = () => {
    setOpenStartedTransaction(false)
  }

  const handleCloseSuccessUpdateTransaction = () => {
    setOpenSuccessUpdateTransaction(false)
  }

  const follow = async () => {
    setPreppingTransaction(true)
    const encodedMessage = await followAddress(provider, account, profileAccount)

    const followTransactionURL = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/followTransactionRelay?params=" + encodedMessage
    const response = await fetch(followTransactionURL);
    const resp_data = await response.json();

    if (resp_data.status == "success") {
      setPreppingTransaction(false)
      const transaction = resp_data.hash;
      setDialogTransactionLink(blockExplorerTx + transaction)
      setDialogTitle("Your follow is being confirmed")
      setOpenStartedTransaction(true)
      setIsFollowing(true)

      waitForFollowTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          var runManual = window.confirm("Transaction Failed. Send transaction manually?");
          if (runManual) {
            submitManualFollowTransaction(encodedMessage)
          }
          handleCloseStartedTransaction()
        }
        else if (receipt.status == "success") {
          if (setFollowUpdated) { // Communicate up
            setFollowUpdated(receipt.hash)
          }
          handleCloseStartedTransaction()

          setDialogTransactionLink(blockExplorerTx + transaction)
          setDialogTitle("Your follow has been confirmed")
          setOpenSuccessUpdateTransaction(true)
        }
      });
    }
    else if (resp_data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualFollowTransaction(encodedMessage)
      }
    }
    else {
      console.log("Transaction Failed")
      var runManual = window.confirm("Transaction Failed. Send transaction manually?");
      if (runManual) {
        submitManualFollowTransaction(encodedMessage)
      }
    }
  }

  const submitManualFollowTransaction = (encodedMessage) => {
    formatFollowTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setDialogTitle("Your follow is being confirmed")
        setOpenStartedTransaction(true)
        setIsFollowing(true)
      }).on('receipt', function(receipt){
        if (setFollowUpdated) { // Communicate up
          setFollowUpdated(receipt.hash)
        }
        handleCloseStartedTransaction()

        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setDialogTitle("Your follow has been confirmed")
        setOpenSuccessUpdateTransaction(true)

        setTimeout(() => {
          window.location.reload()
        }, 1000)

      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransaction()
      });
    })
  };

  const unfollow = async () => {
    setPreppingTransaction(true)
    const encodedMessage = await unfollowAddress(provider, account, profileAccount)

    const unfollowTransactionURL = "https://us-central1-bitprofile-f37a0.cloudfunctions.net/unfollowTransactionRelay?params=" + encodedMessage
    const response = await fetch(unfollowTransactionURL);
    const resp_data = await response.json();

    if (resp_data.status == "success") {
      setPreppingTransaction(false)
      const transaction = resp_data.hash;
      setDialogTransactionLink(blockExplorerTx + transaction)
      setDialogTitle("Your unfollow is being confirmed")
      setOpenStartedTransaction(true)
      setIsFollowing(false)

      waitForUnfollowTransaction(0, transaction, function (receipt) {
        if (receipt.status == "fail") {
          var runManual = window.confirm("Transaction failed. Send transaction manually?");
          if (runManual) {
            submitManualUnfollowTransaction(encodedMessage)
          }
          handleCloseStartedTransaction()
        }
        else if (receipt.status == "success") {
          console.log("success")
          if (setFollowUpdated) { // Communicate up
            setFollowUpdated(receipt.hash)
          }
          handleCloseStartedTransaction()

          setDialogTransactionLink(blockExplorerTx + transaction)
          setDialogTitle("Your unfollow has been confirmed")
          setOpenSuccessUpdateTransaction(true)
        }
      });
    }
    else if (resp_data.status == "transactionLimit") {
      var runManual = window.confirm("You've reached the maximum number of interactions (100) in 24 hours. We will be increasing this daily limit soon. Send transaction manually?");
      if (runManual) {
        submitManualUnfollowTransaction(encodedMessage)
      }
    }
    else {
      var runManual = window.confirm("Transaction failed. Send transaction manually?");
      if (runManual) {
        submitManualUnfollowTransaction(encodedMessage)
      }
    }
  }

  const submitManualUnfollowTransaction = (encodedMessage) => {
    formatUnfollowTransaction(provider, account, encodedMessage, (formattedTransaction) => {
      var transactionHash;
      var web3 = new Web3(provider.provider)
      web3.eth.sendTransaction(formattedTransaction).on('transactionHash', function(hash){
        transactionHash = hash;
        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setDialogTitle("Your follow is being confirmed")
        setOpenStartedTransaction(true)
        setIsFollowing(true)
      }).on('receipt', function(receipt){
        if (setFollowUpdated) { // Communicate up
          setFollowUpdated(receipt.hash)
        }
        handleCloseStartedTransaction()

        setDialogTransactionLink(blockExplorerTx + transactionHash)
        setDialogTitle("Your unfollow has been confirmed")
        setOpenSuccessUpdateTransaction(true)

        setTimeout(() => {
          window.location.reload()
        }, 1000)

      }).on('error', (err) => {
        alert("Failed to run transaction. Please try again. Error: ", err)
        handleCloseStartedTransaction()
      });
    })
  };

  useEffect(() => {
    async function fetchIsFollowing() {
      isFollowingAddress(profileAccount, account, (isFollowing) => {
        setIsFollowing(isFollowing)
      })
    }
    if (profileAccount && account && (profileAccount != account)) {
      setIsFollowing()
      fetchIsFollowing()
    }
  }, [profileAccount, account]);

  return (
    <>
      {
        preppingTransaction ?
          <Box style={style}><CircularProgress /></Box>
        :
          isFollowing ?
            <Button style={style} onClick={unfollow} disableElevation size="large" variant="outlined">
              Unfollow
            </Button>
          :
            isFollowing == false &&
            <Button
              style={style} onClick={follow} disableElevation size="large" variant="contained">
              Follow
            </Button>
      }
      <StartedTransactionDialog
        open={openStartedTransaction}
        onClose={handleCloseStartedTransaction}
        link={dialogTransactionLink}
        title={dialogTitle}
      />

      <SuccessDialog
        open={openSuccessUpdateTransaction}
        onClose={handleCloseSuccessUpdateTransaction}
        link={dialogTransactionLink}
        title={dialogTitle}
      />
    </>
  );
}

export default FollowButton;