import React, { useEffect, useState } from "react";
import { Body, Button, Header, Image } from "./components";

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MoreIcon from '@mui/icons-material/MoreVert';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountIcon from '@mui/icons-material/Face';
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from '@mui/material/TextField';
import { makeStyles } from "@material-ui/core/styles";
import { SearchDialog } from "./Dialogs.js"
import logo from './icon.png';
import { Router, Switch, Route, NavLink, Link, useHistory } from "react-router-dom";

import { cache } from "./Cache.js"
import { ApproveAccountDialog } from "./Dialogs.js";

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [open, setOpen] = React.useState(false);
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        // Resolve the ENS name for the first account.
//        const name = await provider.lookupAddress(accounts[0]);

        // Render either the ENS name or the shortened account address.
//        if (name) {
//          setRendered(name);
//        } else {
//          setRendered(account.substring(0, 5) + "..." + account.substring(38));
//        }
        setRendered(account.substring(0, 5) + "..." + account.substring(38));
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [account, provider, setAccount, setRendered]);

  useEffect(() => {
    async function showApproveAddressIfNeeded() {
      handleClickOpen()
    }

    if (account) {
      var object = JSON.parse(localStorage.getItem("approved_pk"));
      if (!object) {
        showApproveAddressIfNeeded();
      }
    }
  }, [account]);

  return (
    <>
      <Button style={{backgroundColor: "rgb(203,232,253)", color: "#0094ff", fontSize: "12px", fontWeight: "600", marginTop: "5px", marginRight: "0px", width: "140px"}}
        onClick={() => {
          if (!provider) {
            loadWeb3Modal();
          } else {
            localStorage.removeItem('approved_pk');
            logoutOfWeb3Modal();
          }
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {rendered === "" && "Connect Wallet"}
          {rendered !== "" && rendered}
        </div>
      </Button>
      <ApproveAccountDialog
        open={open}
        onClose={handleClose}
        account={account}
        provider={provider}
      />
    </>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none"
//      borderBottomWidth: "1px",
//      borderWidth: "0px",
//      borderRadius: "0px",
//      borderColor: "rgb(196 196 196)"
    },
//    "& .MuiOutlinedInput-input": {
//      color: "green"
//    },
//    "&:hover .MuiOutlinedInput-input": {
//      color: "red"
//    },
//    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
//      color: "purple"
//    },
//    "& .MuiInputLabel-outlined": {
//      color: "green"
//    },
//    "&:hover .MuiInputLabel-outlined": {
//      color: "red"
//    },
//    "& .MuiInputLabel-outlined.Mui-focused": {
//      color: "purple"
//    }
  }
});

function TopAppBar({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchInput, setSearchInput] = React.useState("")
  const [openSearchDialog, setOpenSearchDialog] = useState(false)

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false)
  }

  const openSearch = () => {
    setOpenSearchDialog(true)
  }

  const classes = useStyles();

  let history = useHistory();

  function clearHomeFeedCache() {
    cache("home_feed_pos", 0)
    localStorage.removeItem("feedPosts")
    localStorage.removeItem("globalPosts")
  }

  const goToProfile = () => {
    if (searchInput != "") {
      // If an invalid profile is opened, it won't reload properly. This needs to be fixed. So for now just force reload page.
      setSearchInput("")
      window.location.href = "/" + searchInput
    }
  }

  const openOwnProfile = () => {
    clearHomeFeedCache()
//    history.push('/profile')
    window.location.href = "/profile"
  }

  const openNotifications = () => {
    clearHomeFeedCache()
//    history.push('/notifications')
    window.location.href = "/notifications"
  }

  const openHomeFresh = () => {
    clearHomeFeedCache()
//    history.push('/')
    window.location.href = "/"
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "#fff", boxShadow: "none" }}>
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' }, marginTop: "5px" }}
            >
              <span onClick={openHomeFresh} style={{color: "#000", cursor: "pointer"}} className="navbar-brand"><img style={{width: "30px", height: "30px", marginRight: "10px"}} src={logo} /><strong>NFPosts</strong></span>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <SearchIcon onClick={openSearch} sx={{color: "#777", marginTop: "10px", marginLeft: "0px", cursor: "pointer"}} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <TextField
                className={classes.root}
                variant="outlined"
                placeholder="Search"
                value={searchInput}
                onInput={e => setSearchInput(e.target.value)}
                InputProps={{
                  style: { borderColor: "blue"},
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={goToProfile}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    ev.preventDefault();
                    goToProfile()
                  }
                }}
              />
              <IconButton onClick={openNotifications} size="large" color="inherit" style={{marginTop: "5px"}}>
                <NotificationsIcon style={{color: '#000'}} />
              </IconButton>
              <IconButton onClick={openOwnProfile} size="large" style={{marginTop: "5px"}}>
                <AccountIcon style={{color: '#000'}} />
              </IconButton>
            </Box>
            <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
          </Toolbar>
        </AppBar>
      </Box>
      <SearchDialog
        open={openSearchDialog}
        onClose={handleCloseSearchDialog}
      />
    </>
  );
}

export default TopAppBar