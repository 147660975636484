import React, { useEffect, useState } from "react";
import userImg from '../user.png';
import { styles } from "./Profile.module.css";
import { getImageFromNFTContract } from "../ContractHelpers.js"
import { getProfileNFT, getNFTImageURL, getReverseENS, getENSAvatar } from "../FirebaseRetrieval.js"
import { getFromCache, cache, shouldUseCache } from "../Cache.js"
const { soliditySha3 } = require("web3-utils");
const Web3 = require('web3');

function ProfileNFT({ profileAccount, widthpx, openseaOpenEnabled=false }) {
  const [NFTAvatarOpenSea, setNFTAvatarOpenSea] = useState();
  const [NFTAvatarImg, setNFTAvatarImg] = useState()

  var web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/fee8c943351648ac819a52f3ee66bfbc"));

  useEffect(() => {
    async function fetchImg() {
      getProfileNFT(profileAccount, (nft_dict) => {
        if (nft_dict) {
          const nft_contract_addr = nft_dict.nft_contract_addr
          const nft_tokenid = nft_dict.nft_tokenid
          const tokenHash = soliditySha3(nft_contract_addr, nft_tokenid)

          var cache_name = "img_" + tokenHash
          if (shouldUseCache(cache_name, 5 * 60 * 60 * 24)) {
            const img_url = getFromCache(cache_name)
            setNFTAvatarImg(img_url);

            var opensea_url = "https://opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
            if (nft_dict.chainId == 137) {
                opensea_url = "https://opensea.io/assets/matic/" + nft_contract_addr + "/" + nft_tokenid;
            }
            else if (nft_dict.chainId == 4) {
              opensea_url = "https://testnets.opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
            }
            setNFTAvatarOpenSea(opensea_url);
          }
          else {
            getNFTImageURL(tokenHash, (img_url) => {
              if (img_url) {
                cache(cache_name, img_url)
                var opensea_url = "https://opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
                if (nft_dict.chainId == 137) {
                    opensea_url = "https://opensea.io/assets/matic/" + nft_contract_addr + "/" + nft_tokenid;
                }
                else if (nft_dict.chainId == 4) {
                  opensea_url = "https://testnets.opensea.io/assets/" + nft_contract_addr + "/" + nft_tokenid;
                }
                setNFTAvatarImg(img_url);
                setNFTAvatarOpenSea(opensea_url);
              }
              else {
                // Image is not in firebase
              }
            })
          }
        }
        else {
          getReverseENS(profileAccount).then((ens_name) => {
            if (ens_name != null && ens_name != undefined && ens_name != "" && ens_name.length > 0) {
              getENSAvatar(ens_name, profileAccount).then((avatar_obj) => {
                if (avatar_obj) {
                  const avatarContract = avatar_obj["contract"]
                  const avatarTokenId = avatar_obj["tokenId"]
                  const img_url = avatar_obj["img_url"]

                  setNFTAvatarImg(img_url);
                  const opensea_url = "https://opensea.io/assets/" + avatarContract + "/" + avatarTokenId;
                  setNFTAvatarOpenSea(opensea_url);
                }
              })
            }
          });
        }
      })
    }
    if (profileAccount) {
      setNFTAvatarImg("")
      setNFTAvatarOpenSea("")
      fetchImg()
    }
  }, [profileAccount]);

  const goToOpensea = () => {
    if (NFTAvatarOpenSea && openseaOpenEnabled) {
      window.open(NFTAvatarOpenSea)
    }
  }

  const profileIcon = {
    width: widthpx,
    height: widthpx,
    marginTop: "0px",
    borderRadius: "40px",
    float: "left"
  }

  return (
    <>
      {
      NFTAvatarImg ? <img style={profileIcon} src={NFTAvatarImg} onClick={goToOpensea} />
      : <img style={profileIcon} src={userImg} />
      }
    </>
  );
}

export default ProfileNFT;