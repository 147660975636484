import React, { useEffect, useState } from "react";
import styles from "./Profile.module.css";
import "../Cache.js";
import FollowLabel from "./Followers.js"
import ProfileFeed from "./ProfileFeed.js"
import ProfileNFT from "./Avatar.js"
import TwitterIcon from "../twitter_icon.png"
import FollowButton from "./FollowButton.js"
import Tooltip from '@mui/material/Tooltip';
import openseaLogo from '../opensea.png';
import IconButton from '@mui/material/IconButton';
import { useHistory, useLocation } from "react-router-dom";
import { getPolygonContract, getAvatarMainnetContract } from "../ContractHelpers.js"
import { getProfileNameFromAddress } from "../ContractHelpers.js"
import { getAddressBio,
        getAddressWebsite,
        getProfileNFT,
        getAddressFromTwitterUsername,
        getFollowersCount,
        getFollowingCount,
        getTwitterUsername,
        getUseVerifiedUsername
        } from "../FirebaseRetrieval.js"
import { EditProfileDialog, VerifyProfileDialog } from "../Dialogs.js";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const sigUtil = require('eth-sig-util');
const ethUtil = require('ethereumjs-util');
const abi = require('ethereumjs-abi');
var crypto = require('crypto')
const Web3 = require('web3');

function ProfileAddress({ profileName }) {
  return (
    <h3 className={styles.profileName}>{profileName}</h3>
  );
}

function Bio({ bio }) {
  return (
    <p style={{ marginBottom: "5px", float: "left" }}>{bio}</p>
  );
}

function ProfileLink({ profileLink }) {
  var profileLinkHref = profileLink
  if (!profileLink.includes("http")) {
    profileLinkHref = "https://" + profileLink
  }
  return (
    <a href={profileLinkHref} target="_blank" style={{textDecoration: "none", float: "left"}}>{profileLink}</a>
  );
}

const Profile = (props) => {
  const [account, setAccount] = useState("");
  const [profileAccount, setProfileAccount] = useState("");
  const [profileName, setProfileName] = useState("");
  const [bio, setBio] = useState("");
  const [website, setWebsite] = useState("");
  const [useVerifiedUsername, setUseVerifiedUsername] = useState()
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [NFTAvatarURI, setNFTAvatarURI] = useState("");
  const [NFTAvatarOpenSea, setNFTAvatarOpenSea] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [fullPagesScrolled, setFullPagesScrolled] = useState(0)

  const [isOwnProfile, setIsOwnProfile] = useState()
  const [anchorElOptions, setAnchorElOptions] = React.useState(null);
  const openOptions = Boolean(anchorElOptions);

  const [openEditProfile, setOpenEditProfile] = useState(false)
  const [profileUpdated, setProfileUpdated] = useState()

  const [openVerifyProfile, setOpenVerifyProfile] = useState(false)

  const history = useHistory();
  const location = useLocation();

  const provider = props.provider;

  function clearProfileCache() {
    setFullPagesScrolled(0)
    localStorage.removeItem("profile_feed_pos")
    localStorage.removeItem("profilePosts")
  }

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);
      } catch (err) {
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider]);

  useEffect(() => {
    var web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/fee8c943351648ac819a52f3ee66bfbc"));
    async function loadProfile() {
      const path = location.pathname.substring(1).replace("/collected", "");

      if (path === "profile") {
        if (account) {
          setProfileAccount(account)
          getProfileNameFromAddress(account, (name) => {
            setProfileName(name)
          })
        }
      }
      else if (path.includes(".eth")) {
        web3.eth.ens.getAddress(path).then(function (addr) {
          setProfileAccount(addr)
        });
        setProfileName(path)
      }
      else if (path.charAt(0) == '0' && path.charAt(1) == 'x') {
        setProfileAccount(path);
        getProfileNameFromAddress(path, (name) => {
          setProfileName(name)
        })
      }
      else { // what if its not .eth and not a valid address or username... should have some sort of not valid page
        getAddressFromTwitterUsername(path, (addr) => {
          setProfileAccount(addr)
        })
        setProfileName(path)
      }
    }
    // Not filtering for account since we want it to work even if haven't connect wallet.
    loadProfile()
  }, [account, history.location.pathname, profileUpdated]);

  useEffect(() => {
    async function fetchNetwork(profileAccount) {
      getFollowersCount(profileAccount, (numFollowers) => {
        setFollowersCount(numFollowers);
      })
      getFollowingCount(profileAccount, (numFollowing) => {
        setFollowingCount(numFollowing);
      })
    }
    if (profileAccount) {
      fetchNetwork(profileAccount);
    }
  }, [profileAccount]);

  useEffect(() => {
    async function fetchTwitter(profileAccount) {
      getTwitterUsername(profileAccount, (username) => {
        if (username == "") {
          setTwitterUsername("empty") // this is so the verify button isn't there when profile loading
        }
        else {
          setTwitterUsername(username)
        }
      })
    }
    if (profileAccount) {
      fetchTwitter(profileAccount);
    }
  }, [profileAccount, profileUpdated]);

  useEffect(() => {
    if (profileAccount && account) {
      setIsOwnProfile(profileAccount == account)
    }
  }, [profileAccount, account]);

  useEffect(() => {
    async function fetchUseVerifiedUsername(profileAccount) {
      getUseVerifiedUsername(profileAccount, (useVerified) => {
        setUseVerifiedUsername(useVerified)
      })
    }
    if (profileAccount) {
      fetchUseVerifiedUsername(profileAccount)
    }
  }, [profileAccount]);

  useEffect(() => {
    async function fetchWebsiteAndBio(profileAccount) {
      getAddressBio(profileAccount, (bio) => {
        setBio(bio)
      })

      getAddressWebsite(profileAccount, (website) => {
        setWebsite(website)
      })
    }
    if (profileAccount) {
      fetchWebsiteAndBio(profileAccount)
    }
  }, [profileAccount, profileUpdated]);

  const handleScroll = () => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      const positionY = window.scrollY;
      const listHeight = window.innerHeight;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
    else {
      const positionY = document.getElementById("list").scrollTop;
      const listHeight = document.getElementById("list").offsetHeight / 2;

      const page = parseInt(positionY/listHeight)
      if (page > fullPagesScrolled) {
        setFullPagesScrolled(page)
      }
    }
  };

  useEffect(() => {
    const isMobile = (window.innerHeight / window.innerWidth) > 1
    if (isMobile) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    else {
      document.getElementById("list").addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        document.getElementById("list").removeEventListener('scroll', handleScroll);
      };
    }
  }, [fullPagesScrolled]);

  const openOpenSeaProfile = () => {
    window.open("https://opensea.io/" + profileAccount)
  }

  const handleClickOptions = (event) => {
    setAnchorElOptions(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorElOptions(null);
  };

  const handleCloseEditProfile = () => {
    setOpenEditProfile(false);
  }

  const handleClickOpenEditProfile = () => {
    setOpenEditProfile(true);
  }

  const handleCloseVerifyProfile = () => {
    setOpenVerifyProfile(false);
  }

  const handleClickOpenVerifyProfile = () => {
    setOpenVerifyProfile(true);
  }

  return (
    <>
      <div id="list" style={{marginTop: "5px", height: ((window.innerHeight / window.innerWidth) > 1) ? "auto": "90vh", overflowY: ((window.innerHeight / window.innerWidth) > 1) ? "visible" : "auto" }}>
        <div className={styles.profileInfo}>
          <Paper square elevation={0}  sx={{ pb: '50px', width: 470, maxWidth: "90%", margin: 'auto', paddingBottom: "0px"}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <span style={{cursor: "pointer" }}>
                      <ProfileNFT profileAccount={profileAccount} widthpx={"80px"} openseaOpenEnabled={true} />
                    </span>
                    {
                    isOwnProfile ?
                    <>
                      <Button
                        onClick={handleClickOpenEditProfile}
                        sx={{ marginLeft: "5px", float: "right", marginTop: "35px", borderRadius: "25px", fontWeight: 600 }}
                        style={{textTransform: 'none'}} size="large" variant="outlined"
                       >
                        Edit Profile
                      </Button>
                    </>
                    :
                    <FollowButton
                      style = {{
                        marginLeft: "10px",
                        marginTop: "35px",
                        float: "right",
                        borderRadius: "25px",
                        fontWeight: 600,
                        textTransform: 'none'
                      }}
                      provider={provider}
                      account={account}
                      profileAccount={profileAccount}
                     />
                    }
                    {/*
                    <Button
                      sx={{marginLeft: "10px", float: "right", marginTop: "45px", fontWeight: "600"}}
                      id="basic-button"
                      aria-controls="basic-menu"
                      aria-haspopup="true"
                      aria-expanded={openOptions ? 'true' : undefined}
                      onClick={handleClickOptions}
                    >...</Button>
                    */}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElOptions}
                      open={openOptions}
                      onClose={handleCloseOptions}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                     >
                      <MenuItem onClick={handleCloseOptions}>Profile</MenuItem>
                      <MenuItem onClick={handleCloseOptions}>My account</MenuItem>
                      <MenuItem onClick={handleCloseOptions}>Logout</MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item xs={12} style={{marginTop: "10px"}} >
                    { (useVerifiedUsername && (twitterUsername.includes(".eth") == false)) ?
                        <>
                          <h3 className={styles.profileName}>{twitterUsername}</h3>
                        </>
                      :
                        <ProfileAddress className={styles.profileAddress} profileName={profileName} />
                    }
                    { (twitterUsername != "empty" && twitterUsername != "") &&
                      <Tooltip title="Verified twitter profile" placement="right">
                        <a target="_blank" href={"https://twitter.com/" + twitterUsername} style={{marginLeft: "15px", marginTop: "18px", float: "left"}}><img src={TwitterIcon} style={{height: "20px"}} /></a>
                      </Tooltip>
                    }
                    { (twitterUsername == "empty" && profileAccount == account && account != null && account != undefined) &&
                      <Button
                        style={{textTransform: 'none', float: "left", marginLeft: "15px", marginTop: "18px", fontSize: "9px", borderRadius: "20px"}}
                        variant="outlined"
                        onClick={handleClickOpenVerifyProfile}>
                        Get Verified
                      </Button>
                    }
                    <IconButton
                      style={{textTransform: 'none', float: "left", marginLeft: "10px", marginTop: "12px", fontSize: "9px", borderRadius: "20px"}}
                      onClick={ openOpenSeaProfile } >
                      <Tooltip title="View OpenSea profile" placement="right">
                        <img style={{ width: 22, height: 22 }} src={openseaLogo} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  { bio &&
                    <Grid item xs={12}>
                      <Bio bio={bio} />
                    </Grid>
                  }
                  { website &&
                    <Grid item xs={12}>
                      <ProfileLink profileLink={website} />
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FollowLabel className={styles.followLabel} count={followingCount} showFollowers={false} profileAccount={profileAccount} provider={provider} account={account} />
                <FollowLabel className={styles.followLabel} count={followersCount} showFollowers={true} profileAccount={profileAccount} provider={provider} account={account} />
              </Grid>
            </Grid>
          </ Paper>
          <br/>
          <ProfileFeed provider={provider} profileAccount={profileAccount} account={account} fullPagesScrolled={fullPagesScrolled} ></ProfileFeed>
        </div>
      </div>

      <EditProfileDialog
       open={openEditProfile}
       onClose={handleCloseEditProfile}
       provider={provider}
       account={account}
       setProfileUpdated={setProfileUpdated}
       useVerifiedUsername={useVerifiedUsername}
       twitterUsername={twitterUsername}
      />

      <VerifyProfileDialog
       open={openVerifyProfile}
       onClose={handleCloseVerifyProfile}
       provider={provider}
       account={account}
       setProfileUpdated={setProfileUpdated}
      />
    </>
  );
}

export default Profile;



































